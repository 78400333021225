window.PLPageControlInstall = function (id, separator) {
  document.addEventListener('htmx:afterSettle', () => {
    const pageControl = document.getElementById(id);
    const blockControls: NodeListOf<HTMLElement> = document.querySelectorAll('.pl-block-control');

    function checkOverlap(page: HTMLElement, block: HTMLElement): boolean {
      const rect1: DOMRect = page.getBoundingClientRect();
      const rect2: DOMRect = block.getBoundingClientRect();

      return !(rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom);
    }

    if (pageControl) {
      blockControls.forEach((blockControl, index) => {
        if (checkOverlap(pageControl, blockControl)) {
          blockControl.style.top = `${60}px`;
        }
      });
    }
  });
};

window.PLPageControlHide = function (previous, current, next, separator) {
  if (previous && previous !== '') {
    window.htmx.trigger(previous, 'refresh')
  }
  if (next && next !== '') {
    window.htmx.trigger(next, 'refresh')
  }
  if (current && current !== '') {
    window.htmx.trigger(current, 'refresh')
  }
}

console.log('PLPageControl has been installed');
