/// <reference path="./custom-window.d.ts" />

import MuxPlayerElement from "@mux/mux-player";

window.PLMovieInstall = function (id, playbackId, token, separator) {
  console.log('PLMovie install, playbackId=' + playbackId + ', token=' + token);

  const player = new MuxPlayerElement();
  player.playbackId = playbackId;
  player.playbackToken = token;

  const element = document.getElementById(id);
  element?.append(player);
}

console.log('PLMovie has been installed');
