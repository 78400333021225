/// <reference path="./custom-window.d.ts" />

window.PLFormInstall = function (id, required, submit, separator) {
  console.log('Form install, required=' + required + ', submit=' + submit);

  const submitElement = document.getElementById(submit);
  if (required.length >= 0 && submitElement instanceof HTMLButtonElement) {

    // Install event listeners on required elements
    const currentStatus: Map<string, boolean> = new Map();
    required.forEach((id) => {
      const element = document.getElementById(id);
      if (element instanceof HTMLInputElement) {
        element.addEventListener('input', () => {
          check(currentStatus, element);
          update(currentStatus, submitElement);
        });
        element.addEventListener('change', () => {
          check(currentStatus, element);
          update(currentStatus, submitElement);
        });
        check(currentStatus, element); // Check initial value
      }
    });
    update(currentStatus, submitElement);
  }
}

// Checks an input element for content and updates the status map accordingly
function check(status: Map<string, boolean>, element: HTMLInputElement) {
  if (element.value.trim() === '') {
    status.set(element.id, false);
  } else {
    status.set(element.id, true);
  }
}

window.PLFormUpdate = function (id, status, separator) {
  console.log('Form update, status=' + status);
  status.forEach(([id, kind, message]) => {
    const input = document.getElementById(id) as HTMLInputElement | undefined;
    if (kind === 'invalid')
      input?.setCustomValidity(message);
    else
      input?.setCustomValidity("");
  });
  const form = document.getElementById(id) as HTMLFormElement;
  form.reportValidity();
}

function update(status: Map<string, boolean>, element: HTMLButtonElement) {
  const isValid = Array.from(status.values()).every(value => value);
  element.disabled = !isValid;
}

console.log('PLForm has been installed');
