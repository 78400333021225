import {Loader} from "@googlemaps/js-api-loader"

const googleMapsLoader = new Loader({
  apiKey: "AIzaSyCaPFivw8ZnQQf8k2pxm3smg2QxMpP7Yso",
  version: "weekly",
});

window.PLMapShow = function (id, lat, lng, zoom, separator) {
  const position = {lat: lat, lng: lng};

  const maps = googleMapsLoader.importLibrary('maps');
  const marker = googleMapsLoader.importLibrary('marker');

  Promise.all([maps, marker])
    .then(([{Map}, {AdvancedMarkerElement}]) => {
      let map = new Map(document.getElementById(id + separator + 'map'), {
        zoom: zoom,
        center: position,
        mapId: "DEMO_MAP_ID",
      });

      const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
      });
    })
    .catch((e) => {
    });
}

window.PLMapAddressLookup = function (id, lat, lng, zoom, add_marker, separator) {
  function addressComponent(place, type) {
    return place.address_components.flatMap(component => {
      if (component.types.includes(type))
        return component.long_name;
      else
        return [];
    })[0] || "";
  }

  const position = {lat: lat, lng: lng};

  const maps = googleMapsLoader.importLibrary('maps');
  const places = googleMapsLoader.importLibrary('places');
  const marker = googleMapsLoader.importLibrary('marker');

  Promise.all([maps, places, marker])
    .then(([{Map}, {Places, Autocomplete}, {AdvancedMarkerElement}]) => {
      let map = new Map(document.getElementById(id + separator + 'map'), {
        zoom: zoom,
        center: position,
        mapId: "DEMO_MAP_ID",
      });

      let field = document.getElementById(id + separator + 'autocomplete');
      let autocomplete = new Autocomplete(field);
      autocomplete.bindTo('bounds', map);

      let marker = null;
      if (add_marker) {
        marker = new AdvancedMarkerElement({
          map: map,
          position: {lat: lat, lng: lng}
        });
      }

      autocomplete.addListener('place_changed', function () {
        if (marker) {
          marker.setMap(null);
        }

        let place = autocomplete.getPlace();
        let latitude = place.geometry.location.lat();
        let longitude = place.geometry.location.lng();
        let streetNumber = addressComponent(place, 'street_number');
        let route = addressComponent(place, 'route');
        let postalCode = addressComponent(place, 'postal_code');
        let postalTown = addressComponent(place, 'postal_town');
        let plusCode = place.plus_code?.global_code;

        console.log(place)
        console.log('Coordinate:' + latitude + ', ' + longitude)
        console.log('Address:' + route + ' ' + streetNumber + ', ' + postalCode + ' ' + postalTown)
        console.log('Plus Code:' + plusCode)

        if (!place.geometry) {
          return;
        }

        document.getElementById(id + separator + 'latitude').value = latitude;
        document.getElementById(id + separator + 'longitude').value = longitude;
        document.getElementById(id + separator + 'street').value = route + ' ' + streetNumber;
        document.getElementById(id + separator + 'postal-code').value = postalCode;
        document.getElementById(id + separator + 'city').value = postalTown;
        document.getElementById(id + separator + 'plus-code').value = plusCode;

        marker = new AdvancedMarkerElement({
          map: map,
          position: place.geometry.location
        });

        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
      });
    })
    .catch((e) => {
    });
}

console.log('PLMap has been installed');
