import * as THREE from "three";
import {v4} from "uuid";
import {Camera, Construction, Context, Door, DoorDirection, Meters, Meters2, State, Wall} from "./types";
import {createHandle, disposeAll, getMousePosition, lerp} from "./utils";
import {recreateSide} from "./walls";

export function createDoor(context: Context, state: State, wall: Wall, wallOffset: number, direction: DoorDirection, width: Meters, height: Meters): Door {
  const door: Door = {
    type: 'door',
    id: v4(),
    wall: wall,
    wallOffset: wallOffset,
    direction: direction,
    centerX: lerp(wall.startX[1], wall.endX[1], wallOffset),
    centerY: lerp(wall.startY[1], wall.endY[1], wallOffset),
    paddingX: 0,
    paddingY: 0,
    width: width,
    height: height,
    angle: wall.angle,
    thickness: state.wallThickness,
  };

  // Create door side
  door.side = createSide(context, door, door.width.toWorld(state), door.height.toWorld(state), state.wallThickness.toWorld(state));
  door.side[0].position.set(door.centerX.toWorld(state), door.centerY.toWorld(state), door.height.divide(2).toWorld(state));
  door.side[0].rotation.z = door.angle;
  if (door.side[2]) {
    const length = door.wall.length.toWorld(state);
    const neutralX = length * door.wallOffset - length / 2;
    door.side[2].position.set(neutralX, -0.5, -door.height.divide(2).toWorld(state));
  }
  context.scene.add(door.side[0]);

  // Create door 2D top-down representation slightly above the wall height
  const [group, pivot, contents] = createTop2(door, door.width.toWorld(state), state.wallThickness.toWorld(state), state.doorThickness.toWorld(state));
  door.pivot = pivot;
  door.top = [group, contents];
  door.top[0].position.set(door.centerX.toWorld(state), door.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.2); // slightly above the wall
  door.top[0].rotation.z = door.angle;
  context.scene.add(door.top[0]);

  // Create handles for resizing
  door.center = createHandle(context, state, (handle) => {
    handle.position.set(door.centerX.toWorld(state), door.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.3); // slightly above the top
    handle.userData.isDoor = true;
    handle.userData.construction = door;
    handle.userData.isDoorCenter = true;
  });

  wall.constructions.push(door);
  recreateSide(context, state, wall, 2);
  setDoorDirection(context, state, door);

  return door;
}

export function updateDoor(state: State, door: Door) {
  if (door.side) {
    door.centerX = lerp(door.wall.startX[1], door.wall.endX[1], door.wallOffset);
    door.centerY = lerp(door.wall.startY[1], door.wall.endY[1], door.wallOffset);
    door.angle = door.wall.angle;

    door.side[0].position.set(door.centerX.toWorld(state), door.centerY.toWorld(state), door.side[0].position.z);
    door.side[0].rotation.z = door.angle;
    if (door.side[2]) {
      const length = door.wall.length.toWorld(state);
      const neutralX = length * door.wallOffset - length / 2;
      door.side[2].position.set(neutralX, door.side[2].position.y, door.side[2].position.z);
    }
  }
  if (door.top) {
    door.top[0].position.set(door.centerX.toWorld(state), door.centerY.toWorld(state), door.top[0].position.z);
    door.top[0].rotation.z = door.angle;
  }
  if (door.center) {
    door.center.position.set(door.centerX.toWorld(state), door.centerY.toWorld(state), door.center.position.z);
  }
}

export function removeDoor(context: Context, door: Door) {
  if (door.side) door.side = disposeAll(context, door.side[0]);
  if (door.top) door.top = disposeAll(context, door.top[0]);
  if (door.center) door.center = disposeAll(context, door.center);
  door.wall.constructions = door.wall.constructions.filter(it => it.id != door.id);
}

export function manipulateDoor(context: Context, state: State, mouse: Meters2, isFinished: boolean, door: Door) {
  if (!isFinished && state.lastMouse) {
    if (state.isManipulatingConstruction === 'move') {
      const delta = mouse.subtract(state.lastMouse);

      // Wall vector
      const wallVector = new THREE.Vector2(door.wall.endX[1].meters - door.wall.startX[1].meters, door.wall.endY[1].meters - door.wall.startY[1].meters);

      // Wall length
      const wallLength = wallVector.length();

      // Normalize wall vector
      const wallDirection = wallVector.clone().normalize();

      // Mouse delta vector
      const mouseDelta = new THREE.Vector2(delta.x.meters, delta.y.meters);

      // Project mouse delta onto wall direction
      const projectedDelta = mouseDelta.dot(wallDirection);

      // Calculate the change in wallOffset
      const deltaWallOffset = projectedDelta / wallLength;
      door.wallOffset += deltaWallOffset;

      // Adjust for door width
      const doorWidthAsOffset = (door.width.meters / wallLength) / 2;
      door.wallOffset = Math.max(doorWidthAsOffset, Math.min(1 - doorWidthAsOffset, door.wallOffset));

      updateDoor(state, door);
    }
  } else if (isFinished) {
    recreateSide(context, state, door.wall, 2);
  }
}

export function operateDoor(context: Context, state: State, door: Door, operation: CameraOperation) {
  if (door.pivot) {
    if (operation === 'rotate_left') {
      door.direction = 'left_up';
    } else if (operation === 'rotate_right') {
      door.direction = 'left_down';
    } else if (operation === 'zoom_in') {
      door.direction = 'right_up';
    } else if (operation === 'zoom_out') {
      door.direction = 'right_down';
    }
    setDoorDirection(context, state, door);
  }
}

export function showDoorTop(context: Context, door: Door) {
  if (door.top) door.top[0].visible = true;
}

export function hideDoorTop(context: Context, door: Door) {
  if (door.top) door.top[0].visible = false;
}

export function showDoorSide(context: Context, door: Door) {
  if (door.side) door.side[0].visible = true;
}

export function hideDoorSide(context: Context, door: Door) {
  if (door.side) door.side[0].visible = false;
}

export function showDoorHandle(context: Context, door: Door) {
  if (door.center) door.center.visible = true;
}

export function hideDoorHandle(context: Context, door: Door) {
  if (door.center) door.center.visible = false;
}

export function isDoor(construction: Construction | undefined): Door | undefined {
  if (construction?.type === 'door') {
    return construction as Door;
  }
  return undefined;
}

function createSide(context: Context, door: Door, width: number, height: number, wallThickness: number): [THREE.Object3D, THREE.Mesh[], THREE.Mesh] {
  const frame1 = createFrame(context, door, width, height);
  const frame2 = createFrame(context, door, width, height);
  frame2.rotation.y = Math.PI;

  // Create the geometry (to subtract from the wall)
  const geometry = new THREE.BoxGeometry(width, wallThickness + 1.0, height + 1.0);
  const subtract = new THREE.Mesh(geometry);

  const group = new THREE.Group();
  group.add(frame1);
  group.add(frame2);
  frame1.position.set(0, -0.1, 0);
  frame2.position.set(0, 0.1, 0);
  group.position.set(0, 0, 0);

  group.userData.isDoor = true;
  group.userData.construction = door;
  group.userData.isDoorSide = true;

  return [group, [frame1, frame2], subtract];
}

function createFrame(context: Context, door: Door, width: number, height: number) {
  const geometry = new THREE.PlaneGeometry(width, height);

  const texture = context.textureLoader.load(`/assets/3d/door-side.png`, () => {
    context.renderer.render(context.scene, context.activeCamera);
  });
  const material = new THREE.MeshBasicMaterial({map: texture});
  //const material = new THREE.MeshBasicMaterial({color: 0x305CF9});

  const frame = new THREE.Mesh(geometry, material);
  frame.rotation.x = Math.PI / 2; // Rotate to top-down

  frame.userData.isDoor = true;
  frame.userData.construction = door;
  frame.userData.isDoorPart = true;

  return frame;
}

function createTop(door: Door, width: number, baseThickness: number, doorThickness: number): [THREE.Object3D, THREE.Mesh[]] {
  const geometry = new THREE.BoxGeometry(width, baseThickness, 0.5);
  const material = new THREE.MeshBasicMaterial({color: 0x305CF9});

  const box = new THREE.Mesh(geometry, material);
  box.userData.isDoor = true;
  box.userData.construction = door;
  box.userData.isDoorPart = true;

  const wireframeMaterial = new THREE.MeshBasicMaterial({color: 0xFFFFFF, wireframe: true});
  const wireframe = new THREE.Mesh(geometry, wireframeMaterial);
  wireframe.userData.isDoor = true;
  wireframe.userData.construction = door;
  wireframe.userData.isDoorPart = true;

  const group = new THREE.Group();
  group.add(box);
  group.add(wireframe);
  group.position.set(0, 0, 0);

  group.userData.isDoor = true;
  group.userData.construction = door;
  group.userData.isDoorTop = true;

  return [group, [box, wireframe]];
}

function createTop2(door: Door, width: number, baseThickness: number, doorThickness: number): [THREE.Object3D, THREE.Object3D, THREE.Mesh[]] {
  const baseGeometry = new THREE.PlaneGeometry(width, baseThickness);
  const baseMaterial = new THREE.MeshBasicMaterial({color: 0xFFFFFF, side: THREE.DoubleSide});
  const baseMesh = new THREE.Mesh(baseGeometry, baseMaterial);
  baseMesh.position.set(0, 0, 0);
  baseMesh.userData.isDoor = true;
  baseMesh.userData.construction = door;
  baseMesh.userData.isDoorPart = true;

  const doorGeometry = new THREE.PlaneGeometry(width, doorThickness);
  const doorMaterial = new THREE.MeshBasicMaterial({color: 0x000000, side: THREE.DoubleSide});
  const doorMesh = new THREE.Mesh(doorGeometry, doorMaterial);
  doorMesh.position.set((width + doorThickness) / 2, (width + doorThickness) / 2, 0);
  doorMesh.rotation.z = Math.PI / 2;
  doorMesh.userData.isDoor = true;
  doorMesh.userData.construction = door;
  doorMesh.userData.isDoorPart = true;

  const radius = Math.sqrt(width - doorThickness);

  const arcPath = new THREE.Path();
  arcPath.moveTo(0, radius);
  arcPath.absarc(0, 0, radius, Math.PI, Math.PI / 2, true);

  const points = arcPath.getPoints();
  const arcGeometry = new THREE.BufferGeometry().setFromPoints(points);
  const arcMaterial = new THREE.LineBasicMaterial({
    color: 0x000000,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.5
  });
  const arcLine = new THREE.Line(arcGeometry, arcMaterial);
  arcLine.position.set((width + doorThickness) / 2, 0, 0);
  arcLine.userData.isDoor = true;
  arcLine.userData.construction = door;
  arcLine.userData.isDoorPart = true;

  const pivot = new THREE.Group();
  pivot.add(baseMesh);
  pivot.add(doorMesh);
  pivot.add(arcLine);
  pivot.position.set(0, 0, 0);

  pivot.userData.isDoor = true;
  pivot.userData.construction = door;
  pivot.userData.isDoorPivot = true;

  const group = new THREE.Group();
  group.add(pivot);
  group.position.set(0, 0, 0);

  group.userData.isDoor = true;
  group.userData.construction = door;
  group.userData.isDoorTop = true;

  return [group, pivot, [baseMesh, doorMesh]];
}

function setDoorDirection(context: Context, state: State, door: Door) {
  if (door.pivot) {
    if (door.direction === 'left_up') {
      door.pivot.scale.x = -1;
      door.pivot.rotation.z = 0;
    } else if (door.direction === 'left_down') {
      door.pivot.scale.x = 1;
      door.pivot.rotation.z = Math.PI;
    } else if (door.direction === 'right_up') {
      door.pivot.scale.x = 1;
      door.pivot.rotation.z = 0;
    } else if (door.direction === 'right_down') {
      door.pivot.scale.x = -1;
      door.pivot.rotation.z = Math.PI;
    }
  }
}
