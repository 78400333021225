/// <reference path="./custom-window.d.ts" />

import {hardcoded} from "./Utils";

window.PLInputInstall = function (id, kind, message, separator) {
  console.log('PLInput install, kind=' + kind + ', message=' + message);

  const input = document.getElementById(id) as HTMLInputElement | null;
  if (input) {
    if (kind === 'invalid')
      input?.setCustomValidity(message ?? hardcoded("Unknown error"));
    else
      input?.setCustomValidity("");

    input.addEventListener("input", () => {
      input.setCustomValidity("");
    });
  }
}


window.PLCustomInputSubmit = function (id,  separator) {
  console.log('PLInput Custom submit, id=' + id );
  const submit = document.getElementById("text-picker" + separator + "submit");
   submit?.addEventListener('click', function () {
        const markdown = document.getElementById(id) as HTMLInputElement
        markdown.value
        window.htmx.trigger('#text-picker' + separator + 'custom', 'click')
    });
}

console.log('PLInput has been installed');
