
window.PLMonitoringInstall = function (separator) {
  document.addEventListener('DOMContentLoaded', function (evt) {
    let sessionStart = Date.now(); // Record session start time
    let lastActivity = Date.now(); // Record the last activity time
    let inactivityThreshold = 30000; // 30 seconds of inactivity
    let visitThreshold = 10000; // 10 seconds for a valid page visit
    let inactivityTimer;
    let sessionActive = true;
    let pageVisitRegistered = false;
    let maxScrollPercentage = 0; // Track the maximum scroll depth

    const registerPageVisit = () => {
      if (!pageVisitRegistered) {
        sendEvent('visit', undefined);
        pageVisitRegistered = true;
      }
    };

    // Start a timer to register a valid page visit
    setTimeout(registerPageVisit, visitThreshold);
  });
}

async function sendEvent(type: 'visit' | 'duration' | 'coverage', amount?: number) {
  const formData = new FormData();
  formData.append('type', type);
  await fetch('/pe', {
    method: 'POST',
    body: formData
  });
}

console.log('PLMonitoring has been installed');
