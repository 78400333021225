import * as THREE from "three";
import {v4} from 'uuid';
import {
  ConnectedWall,
  Construction,
  Context,
  Meters,
  Meters2,
  State,
  Wall,
  WallConnection
} from "./types";
import {createHandle, disposeAll, getMousePosition, snapToGrid} from "./utils";
import {
  hideDoorHandle,
  hideDoorSide,
  hideDoorTop,
  isDoor,
  removeDoor,
  showDoorSide,
  showDoorTop,
  updateDoor
} from "./doors";
import {
  hideWindowHandle, hideWindowSide,
  hideWindowTop,
  isWindow,
  removeWindow,
  showWindowSide,
  showWindowTop,
  updateWindow
} from "./windows";
import {CSG} from "three-csg-ts";

export function createWall(context: Context, state: State, start: Meters2, end: Meters2): Wall {
  const snappedStart = snapToGrid(state, start);
  const snappedEnd = snapToGrid(state, end);

  const wall: Wall = {
    type: 'wall',
    id: v4(),
    paddingX: 0,
    paddingY: 0,
    startX: [start.x, snappedStart.x],
    startY: [start.y, snappedStart.y],
    endX: [end.x, snappedEnd.x],
    endY: [end.y, snappedEnd.y],
    length: new Meters(0),
    height: state.wallHeight,
    angle: 0,
    thickness: state.wallThickness,
    constructions: [],
    connectedWalls: [],
  };

  const positionX = (wall.startX[1].toWorld(state) + wall.endX[1].toWorld(state) + 2 * wall.paddingX) / 2;
  const positionY = (wall.startY[1].toWorld(state) + wall.endY[1].toWorld(state) + 2 * wall.paddingY) / 2;

  // Create wall side
  wall.side = createSide(wall, wall.length.toWorld(state), wall.height.toWorld(state), wall.thickness.toWorld(state))
  wall.side[0].position.set(positionX, positionY, wall.height.toWorld(state) / 2);
  wall.side[0].rotation.z = wall.angle;
  context.scene.add(wall.side[0]);

  // Create wall 2D top-down representation slightly above the wall height
  wall.top = createTop(wall, wall.length.toWorld(state), wall.thickness.toWorld(state));
  wall.top[0].position.set(positionX, positionY, wall.height.toWorld(state) + 0.1);
  wall.top[0].rotation.z = wall.angle;
  context.scene.add(wall.top[0]);

  // Create handles for resizing
  wall.start = createHandle(context, state, (handle) => {
    handle.position.set(wall.startX[1].toWorld(state), wall.startY[1].toWorld(state), wall.height.toWorld(state) + 0.2); // slightly above the wall
    handle.userData.isWall = true;
    handle.userData.construction = wall;
    handle.userData.isWallStart = true;
  });
  wall.end = createHandle(context, state, (handle) => {
    handle.position.set(wall.endX[1].toWorld(state), wall.endY[1].toWorld(state), wall.height.toWorld(state) + 0.2); // slightly above the wall
    handle.userData.isWall = true;
    handle.userData.construction = wall;
    handle.userData.isWallEnd = true;
  });

  return wall;
}

export function finishWall(context: Context, state: State, wall: Wall) {
  if (state.lastMouse) {
    const end = state.lastMouse;
    const snappedEnd = snapToGrid(state, end);
    wall.endX = [end.x, snappedEnd.x];
    wall.endY = [end.y, snappedEnd.y];
  }

  updateWall(state, wall, 2);
  state.walls.push(wall);
  connectNearbyWalls(context, state, wall, 0.5);
}

export function updateWall(state: State, wall: Wall, depth: number) {
  const direction = Meters2.fromDirection(wall.startX[1], wall.endX[1], wall.startY[1], wall.endY[1]);
  const positionX = (wall.startX[1].toWorld(state) + wall.endX[1].toWorld(state) + 2 * wall.paddingX) / 2;
  const positionY = (wall.startY[1].toWorld(state) + wall.endY[1].toWorld(state) + 2 * wall.paddingY) / 2;
  wall.angle = direction.angle;
  wall.length = direction.length;

  if (wall.side) {
    wall.side[1][0].geometry.dispose();
    wall.side[1][0].geometry = new THREE.BoxGeometry(wall.length.toWorld(state), wall.thickness.toWorld(state), wall.height.toWorld(state));
    wall.side[0].position.set(positionX, positionY, wall.side[0].position.z);
    wall.side[0].rotation.z = wall.angle;
  }
  if (wall.top) {
    wall.top[1][0].geometry.dispose();
    wall.top[1][0].geometry = new THREE.PlaneGeometry(wall.length.toWorld(state), wall.thickness.toWorld(state));
    wall.top[0].position.set(positionX, positionY, wall.top[0].position.z);
    wall.top[0].rotation.z = wall.angle;
  }
  if (wall.start) {
    wall.start.position.set(wall.startX[1].toWorld(state), wall.startY[1].toWorld(state), wall.start.position.z);
  }
  if (wall.end) {
    wall.end.position.set(wall.endX[1].toWorld(state), wall.endY[1].toWorld(state), wall.end.position.z);
  }
  wall.constructions.forEach(c => {
    const door = isDoor(c);
    const window = isWindow(c);
    if (door) updateDoor(state, door);
    if (window) updateWindow(state, window);
  });
  if (depth > 0) {
    wall.connectedWalls?.forEach((connection) => {
      if (connection.from === 'start' && connection.to === 'start') {
        connection.wall.startX = wall.startX;
        connection.wall.startY = wall.startY;
        updateWall(state, connection.wall, depth - 1);
      } else if (connection.from === 'start' && connection.to === 'end') {
        connection.wall.endX = wall.startX;
        connection.wall.endY = wall.startY;
        updateWall(state, connection.wall, depth - 1);
      } else if (connection.from === 'end' && connection.to === 'start') {
        connection.wall.startX = wall.endX;
        connection.wall.startY = wall.endY;
        updateWall(state, connection.wall, depth - 1);
      } else if (connection.from === 'end' && connection.to === 'end') {
        connection.wall.endX = wall.endX;
        connection.wall.endY = wall.endY;
        updateWall(state, connection.wall, depth - 1);
      }
    });
  }
}

export function removeWall(context: Context, state: State, wall: Wall) {
  if (wall.side) wall.side = disposeAll(context, wall.side[0]);
  if (wall.top) wall.top = disposeAll(context, wall.top[0]);
  if (wall.start) wall.start = disposeAll(context, wall.start);
  if (wall.end) wall.end = disposeAll(context, wall.end);

  // Remove wall from connected walls
  disconnectWall(wall, undefined);

  wall.constructions.forEach(c => {
    const door = isDoor(c);
    const window = isWindow(c);
    if (door) removeDoor(context, door);
    if (window) removeWindow(context, window);
  });

  state.walls = state.walls.filter(w => w.id !== wall.id);
}

export function manipulateWall(context: Context, state: State, mouse: Meters2, isFinished: boolean, wall: Wall) {
  if (state.lastMouse) {
    const snappedMouse = snapToGrid(state, mouse);

    if (state.isManipulatingConstruction === 'move') {
      const delta = mouse.subtract(state.lastMouse);
      const start = new Meters2(wall.startX[0], wall.startY[0]).add(delta);
      const snappedStart = snapToGrid(state, start);
      const end = new Meters2(wall.endX[0], wall.endY[0]).add(delta);
      const snappedEnd = snapToGrid(state, end);

      wall.startX = [start.x, snappedStart.x];
      wall.startY = [start.y, snappedStart.y];
      wall.endX = [end.x, snappedEnd.x];
      wall.endY = [end.y, snappedEnd.y];
    } else if (state.isManipulatingConstruction === 'start') {
      wall.startX = [mouse.x, snappedMouse.x];
      wall.startY = [mouse.y, snappedMouse.y];
    } else if (state.isManipulatingConstruction === 'end' || state.isManipulatingConstruction === 'track') {
      wall.endX = [mouse.x, snappedMouse.x];
      wall.endY = [mouse.y, snappedMouse.y];
    }

    updateWall(state, wall, 2);
    if (isFinished) {
      recreateSide(context, state, wall, 2);
      connectNearbyWalls(context, state, wall, 0.5);
    }
  }
}

export function showWallTop(context: Context, wall: Wall) {
  if (wall.top) wall.top[0].visible = true;
  wall.constructions.forEach(c => {
    const door = isDoor(c);
    const window = isWindow(c);
    if (door) showDoorTop(context, door);
    if (window) showWindowTop(context, window);
  });
}

export function hideWallTop(context: Context, wall: Wall) {
  if (wall.top) wall.top[0].visible = false;
  wall.constructions.forEach(c => {
    const door = isDoor(c);
    const window = isWindow(c);
    if (door) hideDoorTop(context, door);
    if (window) hideWindowTop(context, window);
  });
}

export function showWallSide(context: Context, wall: Wall) {
  if (wall.side) wall.side[0].visible = true;
  wall.constructions.forEach(c => {
    const door = isDoor(c);
    const window = isWindow(c);
    if (door) showDoorSide(context, door);
    if (window) showWindowSide(context, window);
  });
}

export function hideWallSide(context: Context, wall: Wall) {
  if (wall.side) wall.side[0].visible = false;
  wall.constructions.forEach(c => {
    const door = isDoor(c);
    const window = isWindow(c);
    if (door) hideDoorSide(context, door);
    if (window) hideWindowSide(context, window);
  });
}

export function showWallHandle(context: Context, wall: Wall) {
  if (wall.start) wall.start.visible = true;
  if (wall.end) wall.end.visible = true;
}

export function hideWallHandle(context: Context, wall: Wall) {
  if (wall.start) wall.start.visible = false;
  if (wall.end) wall.end.visible = false;
  wall.constructions.forEach(c => {
    const door = isDoor(c);
    const window = isWindow(c);
    if (door) hideDoorHandle(context, door);
    if (window) hideWindowHandle(context, window);
  });
}

export function connectWalls(first: Wall, firstConnection: WallConnection, second: Wall, secondConnection: WallConnection) {
  const id1 = `${second.id}-${firstConnection}-${secondConnection}`;
  const id2 = `${first.id}-${secondConnection}-${firstConnection}`;
  const c1: ConnectedWall = {id: id1, wall: second, from: firstConnection, to: secondConnection};
  const c2: ConnectedWall = {id: id2, wall: first, from: secondConnection, to: firstConnection};
  if (!first.connectedWalls.some(it => it.id === c1.id)) first.connectedWalls.push(c1);
  if (!second.connectedWalls.some(it => it.id === c2.id)) second.connectedWalls.push(c2);
}

export function disconnectWall(wall: Wall, connection?: WallConnection) {
  wall.connectedWalls.forEach((c) => {
    c.wall.connectedWalls = c.wall.connectedWalls.filter(
      it => !(it.wall === wall && (!connection || connection === it.to))
    );
  });
  wall.connectedWalls = wall.connectedWalls.filter(
    it => !(!connection || connection === it.from)
  )
}

export function connectNearbyWalls(context: Context, state: State, newWall: Wall, radius: number): void {
  state.walls.forEach(existingWall => {
    const startPoint = new THREE.Vector2(newWall.startX[1].toWorld(state), newWall.startY[1].toWorld(state));
    const endPoint = new THREE.Vector2(newWall.endX[1].toWorld(state), newWall.endY[1].toWorld(state));
    const existingStart = new THREE.Vector2(existingWall.startX[1].toWorld(state), existingWall.startY[1].toWorld(state));
    const existingEnd = new THREE.Vector2(existingWall.endX[1].toWorld(state), existingWall.endY[1].toWorld(state));

    if (newWall.id === existingWall.id) {
      // Never connect walls to self
    } else if (startPoint.distanceTo(existingStart) < radius) {
      newWall.startX = existingWall.startX;
      newWall.startY = existingWall.startY;
      connectWalls(newWall, 'start', existingWall, 'start');
    } else if (startPoint.distanceTo(existingEnd) < radius) {
      newWall.startX = existingWall.endX;
      newWall.startY = existingWall.endY;
      connectWalls(newWall, 'start', existingWall, 'end');
    } else if (endPoint.distanceTo(existingStart) < radius) {
      newWall.endX = existingWall.startX;
      newWall.endY = existingWall.startY;
      connectWalls(newWall, 'end', existingWall, 'start');
    } else if (endPoint.distanceTo(existingEnd) < radius) {
      newWall.endX = existingWall.endX;
      newWall.endY = existingWall.endY;
      connectWalls(newWall, 'end', existingWall, 'end');
    }
  });
}

export function isWall(construction: Construction | undefined): Wall | undefined {
  if (construction?.type === 'wall') {
    return construction as Wall;
  }
  return undefined;
}

export function recreateSide(context: Context, state: State, wall: Wall, depth: number) {
  const positionX = (wall.startX[1].toWorld(state) + wall.endX[1].toWorld(state) + 2 * wall.paddingX) / 2;
  const positionY = (wall.startY[1].toWorld(state) + wall.endY[1].toWorld(state) + 2 * wall.paddingY) / 2;

  if (wall.side) context.scene.remove(wall.side[0]);
  wall.side = createSide(wall, wall.length.toWorld(state), wall.height.toWorld(state), wall.thickness.toWorld(state))
  wall.side[0].position.set(positionX, positionY, wall.height.toWorld(state) / 2);
  wall.side[0].rotation.z = wall.angle;
  context.scene.add(wall.side[0]);

  if (depth > 0) {
    wall.connectedWalls?.forEach((connection) => {
      recreateSide(context, state, connection.wall, depth - 1);
    });
  }
}

function createSide(wall: Wall, width: number, height: number, wallThickness: number): [THREE.Object3D, THREE.Mesh[], undefined] {
  const geometry = new THREE.BoxGeometry(width, wallThickness, height);
  // const material = new THREE.MeshStandardMaterial({color: 0xD3D3D3, roughness: 0.5, metalness: 0});
  const material = new THREE.MeshBasicMaterial({color: 0xD3D3D3});
  let box: THREE.Mesh = new THREE.Mesh(geometry, material);

  wall.constructions.forEach(construction => {
    const subtraction = construction.side?.[2];
    if (subtraction) {
      // subtraction.position.x = 0;

      box.updateMatrix();
      subtraction.updateMatrix();
      box = CSG.subtract(box, subtraction);
    }
  });

  box.castShadow = true;
  box.receiveShadow = true;

  box.userData.isWall = true;
  box.userData.construction = wall;
  box.userData.isWallPart = true;

  const group = new THREE.Group();
  group.add(box);
  group.position.set(0, 0, 0);

  group.userData.isWall = true;
  group.userData.construction = wall;
  group.userData.isWallSide = true;

  return [group, [box], undefined];
}

function createTop(wall: Wall, width: number, thickness: number): [THREE.Object3D, THREE.Mesh[]] {
  const geometry = new THREE.BoxGeometry(width, thickness);
  const material = new THREE.MeshBasicMaterial({color: 0x000000, side: THREE.DoubleSide});

  const box = new THREE.Mesh(geometry, material);
  box.userData.isWall = true;
  box.userData.construction = wall;
  box.userData.isWallPart = true;

  const group = new THREE.Group();
  group.add(box);
  group.position.set(0, 0, 0);

  group.userData.isWall = true;
  group.userData.construction = wall;
  group.userData.isWallTop = true;

  return [group, [box]];
}
