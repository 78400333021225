import {Camera, directionToNumber, Furniture, Wall} from "./types";
import {isDoor} from "./doors";
import {isWindow} from "./windows";
import {DoorV1, WindowV1, WallV1, FurnitureV1, CameraV1, FileFormatV1} from "proplab-foundation-primitives";

export class FileFormat {
  v1 = FileFormatV1.Companion.empty();

  assign(json: String) {
    this.v1 = Object.assign(this.v1, json);
  }

  stringify(): string {
    return JSON.stringify(this.v1);
  }

  walls(): WallV1[] {
    return this.v1.ws;
  }

  furnitures(): FurnitureV1[] {
    return this.v1.fs;
  }

  cameras(): CameraV1[] {
    return this.v1.cs;
  }

  addWall(wall: Wall) {
    let w = WallV1.Companion.wall(wall.startX[0].meters, wall.endX[0].meters, wall.startY[0].meters, wall.endY[0].meters);

    wall.constructions.forEach((construction) => {
      const door = isDoor(construction);
      const window = isWindow(construction);
      if (door) w = w.addDoor(DoorV1.Companion.door(door.wallOffset, directionToNumber(door.direction), door.width.meters, door.height.meters));
      if (window) w = w.addWindow(WindowV1.Companion.window(window.wallOffset, window.floorOffset.meters, window.width.meters, window.height.meters));
    });
    this.v1 = this.v1.addWall(w);
  }

  addFurniture(furniture: Furniture) {
    const f = FurnitureV1.Companion.furniture(furniture.kind, furniture.centerX.meters, furniture.centerY.meters, furniture.angle);
    this.v1 = this.v1.addFurniture(f);
  }

  addCamera(camera: Camera) {
    const c = CameraV1.Companion.camera(camera.centerX.meters, camera.centerY.meters);
    this.v1 = this.v1.addCamera(c);
  }
}
