/// <reference path="./custom-window.d.ts" />
import { Canvas, StaticCanvas, FabricImage, Image } from 'fabric';
import { EraserBrush } from './EraserBrush';
import { MaskBrush } from './MaskBrush';
import {
  CanvasComponent,
  scaleObject,
  removeBrushes,
  createLoadingCircle,
  fadeOutImage,
  fadeInImages, animateLoadingCircle, fadeOutImages,
} from './FabricUtils';
import { activateButton, hardcoded, inactivateButton, swallow, updateRoot } from "./Utils";

window.PLImageEditorInstall = function (id, project, first, second, isDisabled, isMasking, isErasing, isComparing, separator) {
  console.log('Image Editor install, project=' + project + ', first=' + first + ', second=' + second + ', isDisabled=' + isDisabled + ', isMasking=' + isMasking + ', isErasing=' + isErasing + ', isComparing=' + isComparing);

  // Elements
  const container = document.getElementById(id + separator + 'container');
  const root = document.getElementById(id + separator + 'root');
  const canvas = new Canvas(id + separator + 'drawing');
  canvas.preserveObjectStacking = true;

  const canvasComponent = new CanvasComponent(canvas);
  const firstImageLayerId = canvasComponent.addLayer(false);
  const secondImageLayerId = canvasComponent.addLayer(false);
  const maskLayer = canvasComponent.addLayer(false);
  const loadingLayer = canvasComponent.addLayer(false);

  let panId = id + separator + 'zoom' + separator + 'pan';
  let brushId = id + separator + 'brush';
  let opacityId = id + separator + 'opacity';

  // Zooming and panning
  let isPanning = false;
  let isMouseDown = false;
  let zoomInitial = 0.5;
  let zoomLevel = zoomInitial;
  let moveX = 0;
  let moveY = 0;
  let previousX = 0;
  let previousY = 0;

  // Images
  let firstImage: [string, FabricImage, string] | undefined = undefined;
  let secondImage: [string, FabricImage, string] | undefined = undefined;

  // Last known values
  let lastDisabled = isDisabled;
  let lastMasking = isMasking;
  let lastErasing = isErasing;
  let lastComparing = isComparing;

  canvas.backgroundColor = 'white';
  updateRoot(root, zoomLevel, [moveX, moveY]);

  setupInteractiveControls();
  setupBrushControls(canvas, canvasComponent, maskLayer);

  const loadingCircle = createLoadingCircle(canvasComponent, loadingLayer);

  setImages(first, second, isDisabled, isMasking, isErasing).then();
  toggleProgress(isDisabled);

  canvas.requestRenderAll();

  window.PLFeedbackGenerating = function (progressText, isVisible) {
    console.log('progressText= ' + progressText + 'isVisible ' + isVisible);
    if (root) {
      let label = root.querySelector('p');
      let parent = label?.parentNode as HTMLElement;
      if (label && parent) {

        if (isVisible) {
          parent.style.display = "block";
          label.textContent = progressText;
        } else {
          parent.style.display = "none";
        }
      }
    }
  }

  window.PLImageEditorUpdate = function (id, first, second, isDisabled, isMasking, isErasing, isComparing) {
    console.log('Image Editor update, first=' + first + ', second=' + second + ', isDisabled=' + isDisabled + ', isMasking=' + isMasking + ', isErasing=' + isErasing + ', isComparing=' + isComparing);
    lastDisabled = isDisabled;
    lastMasking = isMasking;
    lastErasing = isErasing;
    lastComparing = isComparing;
    if (!isMasking) {
      canvasComponent.hideLayer(maskLayer);
    }
    setImages(first, second, isDisabled, isMasking, isErasing).then(() => {
      toggleProgress(isDisabled);
      if (isMasking) {
        canvasComponent.showLayer(maskLayer);
      }
      canvas.requestRenderAll();
    });
  };

  window.PLImageEditorTogglePanning = function (event) {
    if (isPanning) {
      stopPanning(canvas, container, secondImage, brushId, panId, lastDisabled, lastMasking, lastErasing);
      isPanning = false;
    } else {
      startPanning(canvas, container, panId);
      isPanning = true;
    }
    container?.focus(); // Always focus container to fix event handlers
  }

  window.PLImageEditorZoom = function (event, delta) {
    zoomLevel *= 0.999 ** delta;
    if (zoomLevel > 20) zoomLevel = 20;
    if (zoomLevel < 0.01) zoomLevel = 0.01;
    updateRoot(root, zoomLevel, [moveX, moveY]);
    container?.focus(); // Always focus container to fix event handlers
  };

  window.PLImageEditorZoomReset = function (event) {
    zoomLevel = zoomInitial;
    updateRoot(root, zoomLevel, [moveX, moveY]);
    container?.focus(); // Always focus container to fix event handlers
  };

  window.PLImageEditorUndo = function (event) {
    const objects = canvasComponent.getLayer(maskLayer)?.objects ?? [];
    if (objects.length > 0) {
      canvasComponent.removeObjectFromLayer(maskLayer, objects[objects.length - 1]);
    }
    if (canvas.freeDrawingBrush instanceof EraserBrush) {
      canvas.freeDrawingBrush.reset()
    }
    canvas.requestRenderAll();
    container?.focus(); // Always focus container to fix event handlers
  };

  window.PLImageEditorClear = function (event) {
    canvasComponent.clearLayer(maskLayer);
    canvas.requestRenderAll();
    container?.focus(); // Always focus container to fix event handlers
  };

  window.PLImageEditorSetMask = async function (event) {
    toggleProgress(true);

    const width = 1024;
    const height = 768;
    const upload = new StaticCanvas(undefined, {
      width: width,
      height: height,
      backgroundColor: 'black',
      enableRetinaScaling: false
    });

    const scale: [number, number] = [width / canvas.width, height / canvas.height];

    const objects = canvasComponent.getLayer(maskLayer)?.objects ?? [];
    await Promise.all(objects.map(async (objectId) => {
      const path = canvasComponent.getObjectById(objectId);
      if (path) {
        const obj = await path.clone();
        obj.set({ stroke: 'white' });
        scaleObject(obj, scale);
        upload.add(obj);
      }
    }));

    upload.renderAll();

    const blob = await new Promise<Blob | null>(resolve => upload.getElement().toBlob(resolve));
    if (blob) {
      const formData = new FormData();
      formData.append('file', blob);
      formData.append('type', 'mask');
      formData.append('project', project);

      const response = await fetch('/image-editor-upload-mask', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        window.htmx.trigger('#image-editor-refresh-' + id, 'click');
        window.PLImageEditorClear(event);
      } else {
        const errorText = await response.text();
        alert(errorText);
      }
    }

    toggleProgress(false);
  };

  window.PLImageEditorRemoveMask = async function (event) {
    toggleProgress(true);

    const formData = new FormData();
    formData.append('type', 'mask');
    formData.append('project', project);
    if (secondImage?.[0]) {
      formData.append('asset', secondImage?.[0]);
    }
    const response = await fetch('/image-editor-remove', {
      method: 'POST',
      body: formData
    });

    if (response.ok) {
      window.htmx.trigger('#image-editor-refresh-' + id, 'click');
      canvasComponent.clearLayer(maskLayer);
    } else {
      const errorText = await response.text();
      alert(errorText);
    }

    toggleProgress(false);
    container?.focus(); // Always focus container to fix event handlers
  };

  window.PLImageEditorSave = async function (event) {
    toggleProgress(true);

    if (lastErasing) {
      const width = 1024;
      const height = 768;
      const upload = new StaticCanvas(undefined, {
        width: width,
        height: height,
        backgroundColor: 'black',
        enableRetinaScaling: false
      });

      const scale: [number, number] = [width / canvas.width, height / canvas.height];

      const img1 = firstImage ?
        firstImage[1].clone().then((obj) => {
          scaleObject(obj, scale);
          upload.insertAt(0, obj);
        })
        : Promise.resolve(null);

      const img2 = secondImage ?
        secondImage[1].clone().then((obj) => {
          scaleObject(obj, scale);
          upload.insertAt(1, obj);
        })
        : Promise.resolve(null);

      await Promise.all([img1, img2]);

      upload.renderAll();

      const blob = await new Promise<Blob | null>(resolve => upload.getElement().toBlob(resolve));
      if (blob) {
        const formData = new FormData();
        formData.append('file', blob);
        formData.append('type', 'result');
        formData.append('project', project);
        if (secondImage?.[0]) {
          formData.append('asset', secondImage?.[0]);
        }

        const response = await fetch('/image-editor-upload-result', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          window.htmx.trigger('#image-editor-refresh-' + id, 'click');
        } else {
          const errorText = await response.text();
          alert(errorText);
        }
      }
    } else {
      const formData = new FormData();
      formData.append('type', 'result');
      formData.append('project', project);
      if (secondImage?.[0]) {
        formData.append('asset', secondImage?.[0]);
      }

      const response = await fetch('/image-editor-move-result', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        window.htmx.trigger('#image-editor-refresh-' + id, 'click');
      } else {
        const errorText = await response.text();
        alert(errorText);
      }
    }

    toggleProgress(false);
    container?.focus(); // Always focus container to fix event handlers
  };

  window.PLImageEditorDownload = async function (event) {
    toggleProgress(true);

    if (lastErasing) {
      const width = 1024;
      const height = 768;
      const upload = new StaticCanvas(undefined, {
        width: width,
        height: height,
        backgroundColor: 'black',
        enableRetinaScaling: false
      });

      const scale: [number, number] = [width / canvas.width, height / canvas.height];

      const img1 = firstImage ?
        firstImage[1].clone().then((obj) => {
          scaleObject(obj, scale);
          upload.insertAt(0, obj);
        })
        : Promise.resolve(null);

      const img2 = secondImage ?
        secondImage[1].clone().then((obj) => {
          scaleObject(obj, scale);
          upload.insertAt(1, obj);
        })
        : Promise.resolve(null);

      await Promise.all([img1, img2]);

      upload.renderAll();

      const blob = await new Promise<Blob | null>(resolve => upload.getElement().toBlob(resolve));
      if (blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = hardcoded('Result.png');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } else if (secondImage?.[2]) {
      const a = document.createElement('a');
      a.href = secondImage?.[2];
      a.download = hardcoded('Result.png');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    toggleProgress(false);
    container?.focus(); // Always focus container to fix event handlers
  };

  window.PLImageEditorDiscard = async function (event) {
    toggleProgress(true);

    const formData = new FormData();
    formData.append('type', 'result');
    formData.append('project', project);
    if (secondImage?.[0]) {
      formData.append('asset', secondImage?.[0]);
    }
    const response = await fetch('/image-editor-remove', {
      method: 'POST',
      body: formData
    });

    if (response.ok) {
      window.htmx.trigger('#image-editor-refresh-' + id, 'click');
    } else {
      const errorText = await response.text();
      alert(errorText);
    }

    toggleProgress(false);
    container?.focus(); // Always focus container to fix event handlers
  };

  async function setImages(first: [string, string] | undefined, second: [string, string] | undefined, isDisabled: boolean, isMasking: boolean, isErasing: boolean) {
    let updateImages = first?.[0] !== firstImage?.[0] || second?.[0] !== secondImage?.[0];
    if (updateImages) {
      await fadeOutImages(canvasComponent, [firstImage?.[1], secondImage?.[1]]);

      const firstImg = await setFirstImage(first);
      const secondImg = await setSecondImage(second);

      let images: [FabricImage, number, number][] = [];

      if (firstImg) {
        images.push([firstImg, 0, 1]);
      } else if (firstImage && firstImage[1].opacity < 1) {
        images.push([firstImage?.[1], 0, 1]);
      }
      if (secondImg) {
        images.push([secondImg, 1, isMasking ? 0.5 : 1]);
      }

      await fadeInImages(canvasComponent, images);
    }

    installOpacityControls();
    installBrushControls();
  }

  async function setFirstImage(image: [string, string] | undefined): Promise<FabricImage | undefined> {
    const [id1] = firstImage ?? []
    const [id2] = image ?? []

    let img: FabricImage | undefined = undefined;

    if (id1 !== id2) {
      canvasComponent.clearLayer(firstImageLayerId);

      if (image && image[1] && image[1] !== '') {
        img = await loadImage(canvas, image);
        firstImage = [image[0], img, image[1]];

        canvasComponent.addObjectToLayer(firstImageLayerId, img);
      } else {
        firstImage = undefined
      }
    }

    if (firstImage) {
      canvasComponent.showLayer(firstImageLayerId);
    } else {
      canvasComponent.hideLayer(firstImageLayerId);
    }

    return img;
  }

  async function setSecondImage(image: [string, string] | undefined): Promise<FabricImage | undefined> {
    const [id1] = secondImage ?? []
    const [id2] = image ?? []

    let img: FabricImage | undefined = undefined;

    if (id1 !== id2) {
      canvasComponent.clearLayer(secondImageLayerId);

      if (image && image[1] && image[1] !== '') {
        img = await loadImage(canvas, image);
        secondImage = [image[0], img, image[1]];

        canvasComponent.addObjectToLayer(secondImageLayerId, img);
      } else {
        secondImage = undefined
      }
      // Some brushes have dependency on second image
      removeBrushes(canvas);
    }

    if (secondImage) {
      canvasComponent.showLayer(secondImageLayerId);
    } else {
      canvasComponent.hideLayer(secondImageLayerId);
    }

    return img;
  }

  async function loadImage(canvas: Canvas, image: [string, string]): Promise<Image> {
    const img = await FabricImage.fromURL(image[1], { 'crossOrigin': 'anonymous' });
    img.evented = false;
    img.hasControls = false;
    img.selectable = false;
    img.opacity = 0;
    img.set('erasable', true);
    img.scaleToWidth(canvas.width);
    img.scaleToHeight(canvas.height);
    return img;
  }

  function toggleProgress(isDisabled: Boolean) {
    if (isDisabled) {
      canvasComponent.showLayer(loadingLayer);
      animateLoadingCircle(canvasComponent, loadingLayer, loadingCircle);
    } else {
      canvasComponent.hideLayer(loadingLayer);
    }
    canvas.requestRenderAll();
  }

  function setupInteractiveControls() {
    // Zooming
    container?.addEventListener('wheel', function (e) {
      if (e.ctrlKey === true || e.metaKey === true) {
        window.PLImageEditorZoom(e, e.deltaY);
      }
    });

    // Panning
    container?.addEventListener('keydown', function (e) {
      if (e.code === 'Space') {
        startPanning(canvas, container, panId);
        isPanning = true;
        swallow(e);
      }
    });
    container?.addEventListener('keyup', function (e) {
      if (e.code === 'Space') {
        stopPanning(canvas, container, secondImage, brushId, panId, lastDisabled, lastMasking, lastErasing);
        isPanning = false;
        swallow(e);
      }
    });
    if (container) {
      container.onmousedown = function (e) {
        if (isPanning) {
          isMouseDown = true;
          previousX = e.clientX;
          previousY = e.clientY;
          swallow(e);
        }
      };
      container.onmouseup = function () {
        isMouseDown = false;
      };
      container.onmousemove = function (e) {
        if (isPanning && isMouseDown) {
          moveX += (e.clientX - previousX) / zoomLevel;
          moveY += (e.clientY - previousY) / zoomLevel;
          previousX = e.clientX;
          previousY = e.clientY;
          updateRoot(root, zoomLevel, [moveX, moveY]);
          swallow(e);
        }
      };
    }
  }

  function installBrushControls() {
    const brush = document.getElementById(brushId);
    updateBrushControls(canvas, container, secondImage, brushId, lastDisabled, lastMasking, lastErasing);
    brush?.addEventListener('input', function (e) {
      updateBrushControls(canvas, container, secondImage, brushId, lastDisabled, lastMasking, lastErasing);
      swallow(e);
    });
  }

  function installOpacityControls() {
    const opacity = document.getElementById(opacityId);
    updateOpacityControls(canvas, secondImage, opacityId, lastMasking, lastComparing);
    opacity?.addEventListener('input', function (e) {
      updateOpacityControls(canvas, secondImage, opacityId, lastMasking, lastComparing);
      swallow(e);
    });
  }
};

function setupBrushControls(canvas: Canvas, canvasComponent: CanvasComponent, maskLayerId: number) {
  // Brush path handling
  canvas.on('path:created', function (e) {
    const path = e.path;
    // Turn off selection for paths created during free drawing mode
    e.path.set({
      evented: false,
      hasControls: false,
      selectable: false
    });

    // Path recording for undo
    canvasComponent.addObjectToLayer(maskLayerId, path, true);
  });
}

function updateOpacityControls(canvas: Canvas, secondImage: [string, FabricImage, string] | undefined, opacityId: string, isMasking: boolean, isComparing: boolean) {
  const opacity = document.getElementById(opacityId) as HTMLInputElement;
  if (isComparing && secondImage) {
    secondImage[1].set({ opacity: (+opacity?.value || 100) / 100 });
    canvas.requestRenderAll();
  } else if (secondImage && isMasking) {
    secondImage[1].set({ opacity: 0.5 });
    canvas.requestRenderAll();
  }
}

function updateBrushControls(canvas: Canvas, container: HTMLElement | null, secondImage: [string, FabricImage, string] | undefined, brushId: string, isDisabled: boolean, isMasking: boolean, isErasing: boolean) {
  const brush = document.getElementById(brushId) as HTMLInputElement;
  if (isErasing && !isDisabled && secondImage && container) {
    if (!(canvas.freeDrawingBrush instanceof EraserBrush)) {
      removeBrushes(canvas);
      canvas.freeDrawingBrush = new EraserBrush(canvas, secondImage[1], container);
    }
    if (canvas.freeDrawingBrush) {
      canvas.freeDrawingBrush.width = +brush?.value || 50;
    }
    canvas.isDrawingMode = true;
  } else if (isMasking && !isDisabled && !secondImage && container) {
    if (!(canvas.freeDrawingBrush instanceof MaskBrush)) {
      removeBrushes(canvas);
      canvas.freeDrawingBrush = new MaskBrush(canvas, container);
    }
    canvas.freeDrawingBrush.color = "rgba(48, 92, 249, 0.4)";
    canvas.freeDrawingBrush.width = +brush?.value || 50;
    canvas.isDrawingMode = true;
  } else {
    removeBrushes(canvas);
    canvas.isDrawingMode = false;
  }
}

function startPanning(canvas: Canvas, container: HTMLElement | null, panId: string) {
  removeBrushes(canvas);
  canvas.isDrawingMode = false;
  canvas.defaultCursor = 'grab';
  activateButton(panId);
  if (container) {
    container.style.cursor = 'grab';
  }
}

function stopPanning(canvas: Canvas, container: HTMLElement | null, secondImage: [string, FabricImage, string] | undefined, brushId: string, panId: string, isDisabled: boolean, isMasking: boolean, isErasing: boolean) {
  updateBrushControls(canvas, container, secondImage, brushId, isDisabled, isMasking, isErasing);
  canvas.defaultCursor = 'default';
  inactivateButton(panId);
  if (container) {
    container.style.cursor = 'default';
  }
}

console.log('PLImageEditor has been installed');
