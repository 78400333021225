window.PLGalleryCounter = function (separator) {
  const checkboxInput = document.querySelectorAll(`[id^="checkbox${separator}"]`);
  const pickerCounterText = document.querySelectorAll(`[id$=value\\${separator}selected]`);
  const removeBtn = document.getElementById("removeMultiple")

  let counter = 0;
  if (removeBtn) {
    removeBtn.disabled = true;
  }
  checkboxInput.forEach((input) => {
    input.addEventListener("change", function () {
      if (input.checked) {
        counter++
        pickerCounterText.forEach((counterText) => {
          counterText.innerText = `${counter}`
        })
      } else {
        counter--
        pickerCounterText.forEach((counterText) => {
          counterText.innerText = `${counter}`
        })
      }
      removeBtn.disabled = counter <= 0;
    })
  })
}

console.log('PLGallery has been installed');
