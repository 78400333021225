/// <reference path="./custom-window.d.ts" />

window.PLCardInstall = function (parent) {
	if (parent) {
		const container = document.getElementById(parent) as HTMLElement;

		function handleChildren() {
			const radios = container.querySelectorAll('input[type="radio"]') as NodeListOf<HTMLInputElement>;
			radios.forEach((radio) => {
				const label = radio.closest('label') as HTMLElement;
				const card = label.closest('div') as HTMLElement;

				const parentElement = radio.closest('div') as HTMLElement;
				const imgChecked = parentElement.querySelector('.checked') as HTMLImageElement;
				const imgUnChecked = parentElement.querySelector('.unChecked') as HTMLImageElement;

				if (radio.checked) {
					imgChecked.style.display = 'block';
					imgUnChecked.style.display = 'none';
					card.classList.add("pl-active-Card");
				} else {
					imgChecked.style.display = 'none';
					imgUnChecked.style.display = 'block';
					card.classList.remove("pl-active-Card");
				}
			})

			container.removeEventListener("change", handleChildren)
		}

		handleChildren()
		container.addEventListener("change", handleChildren)

		window.PLRadioChange = function (event) {
			event.preventDefault();
			const cardElement = event.currentTarget as HTMLElement;
			const radio = cardElement.querySelector('input[type="radio"]') as HTMLInputElement;

			if (radio) {
				if (!radio.checked) {
					radio.checked = true;
					handleChildren();
				}
			}
		};
	}
};

console.log('PLCard has been installed');