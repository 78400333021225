import {BaseBrush, Canvas, FabricImage, ImageSource, Point, TEvent, Circle} from 'fabric';

export class EraserBrush extends BaseBrush {
  private _image: FabricImage
  private _container: HTMLElement
  private _initialImage: ImageSource;
  private _imageCanvas: HTMLCanvasElement;
  private _imageContext: CanvasRenderingContext2D;
  private _customCursor: Circle;

  constructor(canvas: Canvas, image: FabricImage, container: HTMLElement) {
    super(canvas);
    this._image = image
    this._container = container

    // Create an off-screen canvas for image manipulation
    this._imageCanvas = document.createElement('canvas');
    this._imageCanvas.width = image.width!;
    this._imageCanvas.height = image.height!;
    this._imageContext = this._imageCanvas.getContext('2d', {willReadFrequently: true})!;

    // Draw the initial image onto the canvas
    this._initialImage = this._image.getElement();
    this._imageContext.drawImage(this._initialImage, 0, 0);

    // Create custom cursor
    this._customCursor = this._createCursor();
    canvas.add(this._customCursor);
    this._bindCursorEvents(); // Bind event listeners for mouse movement
  }

  private _createCursor(): Circle {
    return new Circle({
      radius: this.width / 2,
      fill: 'rgba(0, 255, 0, 0.2)',
      originX: 'center',
      originY: 'center',
      selectable: false, // Make sure the custom cursor is not selectable
      evented: false, // Disable event handling on the custom cursor
      visible: false, // Initially hidden until mouse is over the canvas
    });
  }

  // Bind custom cursor events
  private _bindCursorEvents() {
    this.canvas.on('mouse:move', (opts) => this._updateCursor(opts));
    this.canvas.on('mouse:out', () => this._hideCursor());
    this.canvas.on('mouse:over', () => this._showCursor());
  }

  // Unbind custom cursor events
  private _unbindCursorEvents() {
    this.canvas.off('mouse:move', this._updateCursor);
    this.canvas.off('mouse:out', this._hideCursor);
    this.canvas.off('mouse:over', this._showCursor);
  }

  private _updateCursor(opts: TEvent) {
    const pointer = this.canvas.getViewportPoint(opts.e);
    this._customCursor.set({
      left: pointer.x,
      top: pointer.y,
      radius: this.width / 2 // Bind the cursor radius to the brush width
    });
    this._customCursor.setCoords();
    this.canvas.requestRenderAll();
  }

  private _hideCursor() {
    this._customCursor.set({visible: false});
    this._container.style.cursor = ''; // Revert to default cursor
    this.canvas.requestRenderAll();
  }

  private _showCursor() {
    this._customCursor.set({visible: true});
    this._container.style.cursor = 'none'; // Hide default cursor
    this.canvas.requestRenderAll();
  }

  private _getFeatheredBrush(eraserSize: number): HTMLCanvasElement {
    const eraserCanvas = document.createElement('canvas');
    eraserCanvas.width = eraserCanvas.height = eraserSize;
    const eraserContext = eraserCanvas.getContext('2d')!;
    const radius = eraserSize / 2;
    let gradient = eraserContext.createRadialGradient(radius, radius, radius / 10, radius, radius, radius);
    gradient.addColorStop(0, 'rgba(0, 0, 0, 1)');
    gradient.addColorStop(0.7, 'rgba(0, 0, 0, 0.05)');
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
    eraserContext.fillStyle = gradient;
    eraserContext.fillRect(0, 0, eraserSize, eraserSize);
    return eraserCanvas;
  }

  private _updateCanvas() {
    // Convert the offscreen canvas back to a FabricImage
    this._image.setElement(this._imageCanvas);
    this._image.dirty = true;
    this.canvas.requestRenderAll();
  }

  private erase(pointer: Point) {
    const eraserSize = this.width
    const x = pointer.x - eraserSize / 2;
    const y = pointer.y - eraserSize / 2;
    const brush = this._getFeatheredBrush(eraserSize);
    this._imageContext.globalCompositeOperation = 'destination-out';
    this._imageContext.drawImage(brush, x, y);
    this._updateCanvas();
  }

  reset() {
    this._imageContext.globalCompositeOperation = 'source-over';
    this._imageContext.drawImage(this._initialImage, 0, 0);
    this._updateCanvas();
  }

  _render() {
  }

  onMouseDown(pointer: Point, {e}: TEvent) {
    this.erase(pointer);
  }

  onMouseMove(pointer: Point, {e}: TEvent) {
    this.erase(pointer);
  }

  onMouseUp({e}: TEvent) {
  }

  // Method to clean up the brush
  cleanUp() {
    this._hideCursor();
    this._unbindCursorEvents();
    this.canvas.remove(this._customCursor);
    this.canvas.requestRenderAll();
  }
}
