window.PLTableInstall = function (id, isClickable, separator) {
  if (isClickable) {
    document.querySelectorAll(`table#${id}${separator}table tbody tr`).forEach(row => {
      row.addEventListener('click', () => {
        htmx.trigger('#' + row.dataset.target, 'row-click');
      });
    });
  }

  document.querySelectorAll(`table#${id}${separator}table button`).forEach(item => {
    item.addEventListener('click', (event) => {
      event.stopPropagation();
    });
  });

  document.querySelectorAll(`table#${id}${separator}table .dropdown-item`).forEach(item => {
    item.addEventListener('click', (event) => {
      event.stopPropagation();
    });
  });
}

console.log('PLTable has been installed');

