
export function hardcoded(s: string) : string {
  return s;
}

export function updateRoot(root: HTMLElement | null, zoomLevel: number, move: [number, number], additional?: (root: HTMLElement) => void ) {
  if (root) {
    root.style.transform = 'scale(' + zoomLevel + ')' + 'translate(' + move[0] + 'px,' + move[1] + 'px)';
    if (additional) { additional(root) }
  }
}

export function swallow(event: Event) {
  event.preventDefault();
  event.stopPropagation();
}

export function activateButton(id: string) {
  const button = document.getElementById(id);
  button?.classList.add('active');
  button?.setAttribute('aria-pressed', 'true');
}

export function inactivateButton(id: string) {
  const button = document.getElementById(id);
  button?.classList.remove('active');
  button?.setAttribute('aria-pressed', 'false');
}

export function setParagraphText(id: string, text: string) {
  const input = document.getElementById(id);
  if (input instanceof HTMLParagraphElement) {
    input.textContent = text;
  }
}

export function setInputText(id: string, text: string, callback: ((text: string) => void) | undefined = undefined) {
  const input = document.getElementById(id);
  if (input instanceof HTMLInputElement) {
    input.value = text;
    if (callback) {
      let debounceTimeout: NodeJS.Timeout | undefined;
      input.addEventListener('input', () => {
        if (debounceTimeout) {
          clearTimeout(debounceTimeout);
        }
        debounceTimeout = setTimeout(() => {
          callback(input.value);
        }, 300);
      });
    }
  }
}
