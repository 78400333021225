import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {USDZLoader} from "three/examples/jsm/loaders/USDZLoader";
import {ReadSignal, WriteSignal} from "@maverick-js/signals";

export type Manipulation = 'start' | 'end' | 'move' | 'rotate' | 'track';
export type WallConnection = 'start' | 'end';
export type DoorDirection = 'left_up' | 'right_up' | 'left_down' | 'right_down';

export function directionToNumber(direction: DoorDirection): number {
  switch (direction) {
    case 'left_up':
      return 0;
    case 'left_down':
      return 1;
    case 'right_up':
      return 2;
    case 'right_down':
      return 3;
  }
}

export function numberToDirection(number: number): DoorDirection {
  switch (number) {
    case 0:
      return 'left_up';
    case 1:
      return 'left_down';
    case 2:
      return 'right_up';
    default:
      return 'right_down';
  }
}

export interface ConnectedWall {
  id: string,
  from: WallConnection,
  to: WallConnection,
  wall: Wall
}

export interface Construction {
  type: 'wall' | 'door' | 'window' | 'furniture' | 'camera' | 'reference';
  id: string;
  paddingX: number; // Increases the mesh size
  paddingY: number; // Increases the mesh size
  height: Meters;
  angle: number;
  thickness: Meters;
  side?: [group: THREE.Object3D, contents: THREE.Mesh[], subtract: THREE.Mesh | undefined];
  top?: [group: THREE.Object3D, contents: THREE.Mesh[]];
}

export interface Wall extends Construction {
  startX: [raw: Meters, snapped: Meters];
  startY: [raw: Meters, snapped: Meters];
  endX: [raw: Meters, snapped: Meters];
  endY: [raw: Meters, snapped: Meters];
  length: Meters;
  start?: THREE.Object3D;
  end?: THREE.Object3D;
  constructions: Construction[];
  connectedWalls: ConnectedWall[];
}

export interface Door extends Construction {
  centerX: Meters;
  centerY: Meters;
  wall: Wall;
  wallOffset: number; // Between 0 and 1
  width: Meters;
  direction: DoorDirection;
  center?: THREE.Object3D;
  pivot?: THREE.Object3D;
}

export interface Window extends Construction {
  centerX: Meters;
  centerY: Meters;
  wall: Wall;
  wallOffset: number; // Between 0 and 1
  floorOffset: Meters; // Actual distance from window lower edge to floor
  width: Meters;
  originalWidth: Meters;
  originalScale: THREE.Vector3;
  start?: THREE.Object3D;
  end?: THREE.Object3D;
}

export interface Furniture extends Construction {
  kind: string,
  centerX: Meters;
  centerY: Meters;
  width: Meters;
  rotate?: THREE.Object3D;
}

export interface Camera extends Construction {
  centerX: Meters;
  centerY: Meters;
  center?: THREE.Object3D;
  perspectiveCamera?: THREE.PerspectiveCamera;
  target?: THREE.Object3D;
  directionalLight?: THREE.DirectionalLight;
}

export interface Reference extends Construction {
  centerX: Meters;
  centerY: Meters;
  width: Meters;
  scaleX: number;
  scaleY: number;
  topLeft?: THREE.Object3D;
  bottomLeft?: THREE.Object3D;
  topRight?: THREE.Object3D;
  bottomRight?: THREE.Object3D;
}

export interface State {
  tool: Tool;
  show: Show;
  isSnapping: boolean;
  selectedConstruction?: Construction;
  isManipulatingConstruction?: Manipulation;
  initialMouse?: Meters2;
  lastMouse?: Meters2;
  initialAngle?: number;
  unfinishedWall?: Wall;
  walls: Wall[];
  furnitures: Furniture[];
  cameras: Camera[];
  reference?: Reference;
  wallHeight: Meters;
  wallThickness: Meters;
  doorThickness: Meters;
  doorHeight: Meters;
  doorWidth: Meters;
  windowHeight: Meters;
  windowWidth: Meters;
  windowOffset: Meters;

  $handleSize: ReadSignal<number>;
  $gridSize: ReadSignal<Meters>;
  $snapSize: ReadSignal<Meters>;
  $scale: ReadSignal<number>;
}

export interface Context {
  container: HTMLElement,
  scene: THREE.Scene;
  aspect: number;
  orthographicCamera: THREE.OrthographicCamera;
  activeCamera: THREE.Camera;
  renderer: THREE.WebGLRenderer;
  raycaster: THREE.Raycaster;
  floor: THREE.Mesh;
  ceiling: THREE.Mesh;
  gltfLoader: GLTFLoader;
  usdzLoader: USDZLoader;
  textureLoader: THREE.TextureLoader;
  grid?: THREE.GridHelper;

  panId(): string;

  selectionId(): string;

  wallsSingleId(): string;

  wallsContinuousId(): string;

  constructionId(): string;

  lengthId(): string;
}

export class Meters {
  readonly meters: number;

  constructor(meters: number) {
    this.meters = meters;
  }

  add(meters: number): Meters;
  add(other: Meters): Meters;
  add(value: number | Meters): Meters {
    if (typeof value === 'number') {
      return new Meters(this.meters + value);
    } else {
      return new Meters(this.meters + value.meters);
    }
  }

  subtract(meters: number): Meters;
  subtract(other: Meters): Meters;
  subtract(value: number | Meters): Meters {
    if (typeof value === 'number') {
      return new Meters(this.meters - value);
    } else {
      return new Meters(this.meters - value.meters);
    }
  }

  divide(meters: number): Meters;
  divide(other: Meters): Meters;
  divide(value: number | Meters): Meters {
    if (typeof value === 'number') {
      return new Meters(this.meters / value);
    } else {
      return new Meters(this.meters / value.meters);
    }
  }

  multiply(meters: number): Meters;
  multiply(other: Meters): Meters;
  multiply(value: number | Meters): Meters {
    if (typeof value === 'number') {
      return new Meters(this.meters * value);
    } else {
      return new Meters(this.meters * value.meters);
    }
  }

  toCentimeters(): Meters {
    return new Meters(this.meters * 100);
  }

  toWorld(state: State): number {
    return this.meters * state.$scale();
  }

  static zero = new Meters(0);

  static fromCentimeters(centimeters: number): Meters {
    return new Meters(centimeters / 100);
  }

  static fromWorld(state: State, world: number): Meters {
    return new Meters(world / state.$scale());
  }
}

export class Meters2 {
  readonly x: Meters;
  readonly y: Meters;

  get angle(): number {
    return Math.atan2(this.y.meters, this.x.meters);
  }

  get length(): Meters {
    return new Meters(Math.sqrt(this.x.meters ** 2 + this.y.meters ** 2));
  }

  constructor(x: number, y: number);
  constructor(x: Meters, y: Meters);
  constructor(x: number | Meters, y: number | Meters) {
    if (typeof x === "number" && typeof y === "number") {
      this.x = new Meters(x);
      this.y = new Meters(y);
    } else if (x instanceof Meters && y instanceof Meters) {
      this.x = x;
      this.y = y;
    } else {
      throw new Error("Invalid constructor arguments.");
    }
  }

  add(x: number, y: number): Meters2;
  add(x: Meters, y: Meters): Meters2;
  add(other: Meters2): Meters2;
  add(value1: number | Meters | Meters2, value2?: number | Meters): Meters2 {
    if (typeof value1 === 'number' && typeof value2 === 'number') {
      return new Meters2(this.x.add(value1), this.y.add(value2));
    } else if (value1 instanceof Meters && value2 instanceof Meters) {
      return new Meters2(this.x.add(value1), this.y.add(value2));
    } else if (value1 instanceof Meters2) {
      return new Meters2(this.x.add(value1.x), this.y.add(value1.y));
    } else {
      throw new Error("Invalid arguments for add method.");
    }
  }

  subtract(x: number, y: number): Meters2;
  subtract(x: Meters, y: Meters): Meters2;
  subtract(other: Meters2): Meters2;
  subtract(value1: number | Meters | Meters2, value2?: number | Meters): Meters2 {
    if (typeof value1 === 'number' && typeof value2 === 'number') {
      return new Meters2(this.x.subtract(value1), this.y.subtract(value2));
    } else if (value1 instanceof Meters && value2 instanceof Meters) {
      return new Meters2(this.x.subtract(value1), this.y.subtract(value2));
    } else if (value1 instanceof Meters2) {
      return new Meters2(this.x.subtract(value1.x), this.y.subtract(value1.y));
    } else {
      throw new Error("Invalid arguments for subtract method.");
    }
  }

  static fromDirection(startX: number, endX: number, startY: number, endY: number): Meters2;
  static fromDirection(startX: Meters, endX: Meters, startY: Meters, endY: Meters): Meters2;
  static fromDirection(startX: number | Meters, endX: number | Meters, startY: number | Meters, endY: number | Meters): Meters2 {
    if (typeof startX === "number" && typeof endX === "number" && typeof startY === "number" && typeof endY === "number") {
      return new Meters2(endX - startX, endY - startY);
    } else if (startX instanceof Meters && endX instanceof Meters && startY instanceof Meters && endY instanceof Meters) {
      return new Meters2(endX.meters - startX.meters, endY.meters - startY.meters);
    } else {
      throw new Error("Invalid arguments for fromDirection method.");
    }
  }

  static fromWorld(state: State, x: number, y: number): Meters2 {
    return new Meters2(Meters.fromWorld(state, x), Meters.fromWorld(state, y));
  }
}

export class Meters3 {
  readonly x: Meters;
  readonly y: Meters;
  readonly z: Meters;

  constructor(x: number, y: number, z: number);
  constructor(x: Meters, y: Meters, z: Meters);
  constructor(x: number | Meters, y: number | Meters, z: number | Meters) {
    if (typeof x === "number" && typeof y === "number" && typeof z === "number") {
      this.x = new Meters(x);
      this.y = new Meters(y);
      this.z = new Meters(z);
    } else if (x instanceof Meters && y instanceof Meters && z instanceof Meters) {
      this.x = x;
      this.y = y;
      this.z = z;
    } else {
      throw new Error("Invalid constructor arguments.");
    }
  }

  add(other: Meters3): Meters3 {
    return new Meters3(this.x.add(other.x.meters), this.y.add(other.y.meters), this.z.add(other.z.meters));
  }

  subtract(other: Meters3): Meters3 {
    return new Meters3(this.x.subtract(other.x), this.y.subtract(other.y), this.z.subtract(other.z));
  }

  static fromDirection(startX: number, endX: number, startY: number, endY: number, startZ: number, endZ: number): Meters3;
  static fromDirection(startX: Meters, endX: Meters, startY: Meters, endY: Meters, startZ: Meters, endZ: Meters): Meters3;
  static fromDirection(startX: number | Meters, endX: number | Meters, startY: number | Meters, endY: number | Meters, startZ: number | Meters, endZ: number | Meters): Meters3 {
    if (typeof startX === "number" && typeof endX === "number" && typeof startY === "number" && typeof endY === "number" && typeof startZ === "number" && typeof endZ === "number") {
      return new Meters3(endX - startX, endY - startY, endZ - startZ);
    } else if (startX instanceof Meters && endX instanceof Meters && startY instanceof Meters && endY instanceof Meters && startZ instanceof Meters && endZ instanceof Meters) {
      return new Meters3(endX.meters - startX.meters, endY.meters - startY.meters, endZ.meters - startZ.meters);
    } else {
      throw new Error("Invalid arguments for fromDirection method.");
    }
  }
}
