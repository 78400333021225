import * as THREE from "three";
import {v4} from "uuid";
import {Camera, Construction, Context, Meters, Meters2, State} from "./types";
import {createHandle, disposeAll} from "./utils";

export function createCamera(context: Context, state: State, center: Meters2): Camera {
  const camera: Camera = {
    type: 'camera',
    id: v4(),
    centerX: center.x,
    centerY: center.y,
    paddingX: 0,
    paddingY: 0,
    height: new Meters(0.5),
    angle: 0,
    thickness: state.wallThickness,
  };

  // Create camera side geometry
  camera.top = createTop(camera, camera.height.toWorld(state));
  camera.top[0].position.set(camera.centerX.toWorld(state), camera.centerY.toWorld(state), camera.height.divide(2).toWorld(state));
  context.scene.add(camera.top[0]);

  // Create handles for resizing
  camera.center = createHandle(context, state, (handle) => {
    handle.position.set(camera.centerX.toWorld(state), camera.centerY.toWorld(state), camera.height.divide(2).toWorld(state) + 0.1);
    handle.userData.isCamera = true;
    handle.userData.construction = camera;
    handle.userData.isCameraCenter = true;
  });

  camera.perspectiveCamera = new THREE.PerspectiveCamera(75, context.aspect, 1, 1000);
  camera.perspectiveCamera.position.set(0, -50, 1.5); // TODO: Investigate why we need to init these values like this
  camera.perspectiveCamera.lookAt(0, 0, 1);
  camera.perspectiveCamera.up.set(0, 1, 0);
  context.scene.add(camera.perspectiveCamera);

  camera.target = new THREE.Object3D();
  camera.target.position.set(0, 0, 10);
  context.scene.add(camera.target);

  // Disable directional light for now
/*  camera.directionalLight = new THREE.DirectionalLight(0xFFFFFF, 1); // White light with intensity
  camera.directionalLight.position.x = camera.centerX.toWorld(state);
  camera.directionalLight.position.y = camera.centerY.toWorld(state);
  camera.directionalLight.target = camera.target;
  camera.directionalLight.intensity = 2;
  camera.directionalLight.color.setHex(0xFFFFFF);
  camera.directionalLight.castShadow = true;
  camera.directionalLight.shadow.mapSize.width = 1024;
  camera.directionalLight.shadow.mapSize.height = 1024;
  camera.directionalLight.position.set(0, -50, 20);
  context.scene.add(camera.directionalLight);*/

  state.cameras.push(camera);

  return camera;
}

export function updateCamera(context: Context, state: State, camera: Camera) {
  if (camera.side) {
  }
  if (camera.top) {
    camera.top[0].position.set(camera.centerX.toWorld(state), camera.centerY.toWorld(state), camera.top[0].position.z);
  }
  if (camera.center) {
    camera.center.position.set(camera.centerX.toWorld(state), camera.centerY.toWorld(state), camera.center.position.z);
  }
  if (camera.perspectiveCamera) {
    camera.perspectiveCamera.position.x = camera.centerX.toWorld(state);
    camera.perspectiveCamera.position.y = camera.centerY.toWorld(state);
  }
  if (camera.directionalLight) {
    camera.directionalLight.position.x = camera.centerX.toWorld(state);
    camera.directionalLight.position.y = camera.centerY.toWorld(state);
  }
}

export function removeCamera(context: Context, camera: Camera) {
  if (camera.side) camera.side = disposeAll(context, camera.side[0]);
  if (camera.top) camera.top = disposeAll(context, camera.top[0]);
  if (camera.center) camera.center = disposeAll(context, camera.center);
  if (camera.perspectiveCamera) camera.perspectiveCamera = disposeAll(context, camera.perspectiveCamera);
  if (camera.target) camera.target = disposeAll(context, camera.target);
  if (camera.directionalLight) camera.directionalLight = disposeAll(context, camera.directionalLight);
}

export function manipulateCamera(context: Context, state: State, mouse: Meters2, isFinished: boolean, camera: Camera) {
  if (!isFinished && state.lastMouse) {
    if (state.isManipulatingConstruction === 'move') {
      const delta = mouse.subtract(state.lastMouse);

      camera.centerX = camera.centerX.add(delta.x);
      camera.centerY = camera.centerY.add(delta.y);

      updateCamera(context, state, camera);
    }
  }
}

export function activateCamera(context: Context, state: State, camera: Camera) {
  updateCamera(context, state, camera);
  if (camera.perspectiveCamera) {
    camera.perspectiveCamera.aspect = context.aspect;
    camera.perspectiveCamera.updateProjectionMatrix();
    // Disable floor and ceiling size calculations for now
//    const distance = camera.perspectiveCamera.position.length(); // Distance from the origin
//    const height = 2 * Math.tan((camera.perspectiveCamera.fov * Math.PI / 180) / 2) * distance;
//    const width = height * context.aspect;
//    context.floor.scale.set(width, height, 1);
//    context.ceiling.scale.set(width, height, 1);
  }
}

export function operateCamera(context: Context, state: State, camera: Camera, operation: CameraOperation) {
  if (camera.perspectiveCamera) {
    if (operation === 'rotate_left') {
      camera.perspectiveCamera.rotation.y += 0.1;
      // context.directionalLight.rotation.y = context.perspectiveCamera.rotation.y;
    } else if (operation === 'rotate_right') {
      camera.perspectiveCamera.rotation.y -= 0.1;
      // context.directionalLight.rotation.y = context.perspectiveCamera.rotation.y;
    } else if (operation === 'zoom_in') {
      camera.perspectiveCamera.fov -= 1; // Decrease FOV for zooming in
      if (camera.perspectiveCamera.fov < 10) camera.perspectiveCamera.fov = 10; // Set a minimum FOV
    } else if (operation === 'zoom_out') {
      camera.perspectiveCamera.fov += 1; // Increase FOV for zooming out
      if (camera.perspectiveCamera.fov > 75) camera.perspectiveCamera.fov = 75; // Set a maximum FOV
    }
    camera.perspectiveCamera.updateProjectionMatrix();
  }
}

export function showCameraTop(context: Context, camera: Camera) {
  if (camera.top) camera.top[0].visible = true;
}

export function hideCameraTop(context: Context, camera: Camera) {
  if (camera.top) camera.top[0].visible = false;
}

export function showCameraSide(context: Context, camera: Camera) {
  if (camera.side) camera.side[0].visible = true;
}

export function hideCameraSide(context: Context, camera: Camera) {
  if (camera.side) camera.side[0].visible = false;
}

export function showCameraHandle(context: Context, camera: Camera) {
  if (camera.center) camera.center.visible = true;
}

export function hideCameraHandle(context: Context, camera: Camera) {
  if (camera.center) camera.center.visible = false;
}

export function isCamera(construction: Construction | undefined): Camera | undefined {
  if (construction?.type === 'camera') {
    return construction as Camera;
  }
  return undefined;
}

function createTop(camera: Camera, size: number): [THREE.Object3D, THREE.Mesh[]] {
  const geometry = new THREE.BoxGeometry(size, size, size);
  const material = new THREE.MeshBasicMaterial({color: 0xFFD700});
  let box: THREE.Mesh = new THREE.Mesh(geometry, material);

  box.userData.isCamera = true;
  box.userData.construction = camera;
  box.userData.isCameraPart = true;

  const group = new THREE.Group();
  group.add(box);
  group.position.set(0, 0, 0);

  group.userData.isCamera = true;
  group.userData.construction = camera;
  group.userData.isCameraSide = true;

  return [group, [box]];
}
