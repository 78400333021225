/// <reference path="./custom-window.d.ts" />

window.PLSelectInputInstall = function (id, separator) {
  const selectElement = document.getElementById(id);

  if (selectElement instanceof HTMLSelectElement) {
    selectElement.addEventListener("change", (e) => {
      const selectedOption = selectElement.selectedOptions[0];
      const replace = selectedOption.getAttribute("data-replace");
      if (replace) {
        selectElement.setAttribute('hx-patch', replace);
        window.htmx.process(selectElement);
        window.htmx.trigger(selectElement, 'custom');
      }
    })
  }
}

console.log('PLSelectInput has been installed');
