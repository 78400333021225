/// <reference path ="./custom-window.d.ts" />

window.HXDisableTarget = function (id, targets, separator) {
	console.log('Disable Target, id=' + id + ', targets=' + targets);

	const element = document.getElementById(id)
	if (element !== null) {
		element.addEventListener('htmx:configRequest', function (evt) {
			document.addEventListener('htmx:afterSwap', handleAfterSwap);

			targets.forEach((target) => {
				const targetElement = document.getElementById(target)
				if (targetElement) {
					targetElement.style.pointerEvents = "none";
				}
			});
		});
	}

	function handleAfterSwap() {
		targets.forEach((target) => {
			const targetElement = document.getElementById(target)
			if (targetElement) {
				targetElement.style.pointerEvents = "auto";
			}
		});

		document.removeEventListener('3', handleAfterSwap);
	}
}

console.log("HXDisableTarget has been installed");
