import {Canvas, Circle, FabricObject, Image, Rect, util} from "fabric";
import {EraserBrush} from "./EraserBrush";
import {MaskBrush} from "./MaskBrush";
import {generateUUID} from "three/src/math/MathUtils";

export class CanvasComponent {
  canvas: Canvas;
  layers: Layer[];

  constructor(canvas: Canvas) {
    this.canvas = canvas;
    this.layers = [];
  }

  getObjectById(id: string): FabricObject | undefined {
    return this.canvas?.getObjects().find(obj => obj.id === id);
  }

  getLayer(layerId: number): Layer | undefined {
    if (!this.canvas) return undefined;

    return this.layers.find(layer => layer.id === layerId);
  }

  addLayer(visible: boolean): number {
    const newLayer = new Layer(this.layers.length, visible);
    this.layers.push(newLayer);
    return newLayer.id;
  }

  addObjectToLayer(layerId: number, object: FabricObject, alreadyAddedToCanvas: boolean = false) {
    const layer = this.getLayer(layerId);
    if (!layer) return;

    object.id = generateUUID();

    if (!alreadyAddedToCanvas) {
      this.canvas.add(object);
    }
    layer.objects.push(object.id);
    this.rearrangeObjects();
  }

  removeObjectFromLayer(layerId: number, objectId: string) {
    const layer = this.getLayer(layerId);
    if (!layer) return;
    
    const object = this.getObjectById(objectId);

    if (object) {
      this.canvas.remove(object);
      layer.objects = layer.objects.filter(id => id !== objectId);
    }
  }

  clearLayer(layerId: number) {
    const layer = this.getLayer(layerId);
    if (!layer) return;

    layer.objects.forEach((objectId) => {
      const object = this.getObjectById(objectId);
      if (object)
        this.canvas.remove(object);
    });
    layer.objects = [];
  }

  showLayer(layerId: number) {
    const layer = this.getLayer(layerId);
    if (!layer) return;

    layer.visible = true;
    layer.objects.forEach((objectId) => {
      const object = this.getObjectById(objectId);
      object?.set('visible', layer.visible);
    });
  }

  hideLayer(layerId: number) {
    const layer = this.getLayer(layerId);
    if (!layer) return;

    layer.visible = false;
    layer.objects.forEach((objectId) => {
      const object = this.getObjectById(objectId);
      object?.set('visible', layer.visible);
    });
  }

  isLayerVisible(layerId: number) {
    const layer = this.getLayer(layerId);
    if (!layer) return;
    
    return layer.visible;
  }

  rearrangeObjects() {
    let index = 0;
    for (let layer of this.layers) {
      for (let objectId of layer.objects) {
        const object = this.getObjectById(objectId);
        if (object)
          this.canvas.moveObjectTo(object, index);
        index++;
      }
    }
    this.canvas.requestRenderAll();
  }
}

export class Layer {
  id: number;
  visible: boolean;
  objects: string[];

  constructor(id: number, visible: boolean) {
    this.id = id;
    this.visible = visible;
    this.objects = [];
  }
}

export function scaleObject(obj: FabricObject, scale: [x: number, y: number]) {
  const currentScaleX = obj.scaleX;
  const currentScaleY = obj.scaleY;
  const currentLeft = obj.left;
  const currentTop = obj.top;
  obj.scaleX = currentScaleX * scale[0];
  obj.scaleY = currentScaleY * scale[1];
  obj.left = currentLeft * scale[0];
  obj.top = currentTop * scale[1];
  obj.setCoords();
}

export function removeBrushes(canvas: Canvas) {
  if (canvas.freeDrawingBrush instanceof EraserBrush) {
    canvas.freeDrawingBrush.cleanUp();
  }
  if (canvas.freeDrawingBrush instanceof MaskBrush) {
    canvas.freeDrawingBrush.cleanUp();
  }
  canvas.freeDrawingBrush = undefined;
}

export function createLoadingCircle(canvasComponent: CanvasComponent, layerId: number): Circle {
  const overlay = new Rect({
    left: 0,
    top: 0,
    width: canvasComponent.canvas.getWidth(),
    height: canvasComponent.canvas.getHeight(),
    fill: 'rgba(255, 255, 255, 0.5)',
    selectable: false,
    evented: false,
  });
  canvasComponent.addObjectToLayer(layerId, overlay);

  const loadingCircle = new Circle({
    radius: 30,
    startAngle: 0,
    endAngle: 60,
    fill: 'rgba(0, 0, 0, 0)',
    stroke: '#3498db',
    strokeWidth: 5,
    originX: 'center',
    originY: 'center',
    left: canvasComponent.canvas.getWidth() / 2,
    top: canvasComponent.canvas.getHeight() / 2,
  });

  canvasComponent.addObjectToLayer(layerId, loadingCircle);
  
  return loadingCircle;
}

export function animateLoadingCircle(canvasComponent: CanvasComponent, layerId: number, loadingCircle: Circle) {
  function animateLoadingCircle() {
    if (canvasComponent.isLayerVisible(layerId)) {
      loadingCircle.animate(
          {angle: loadingCircle.angle + 360},
          {
            onChange: canvasComponent.canvas.renderAll.bind(canvasComponent.canvas),
            duration: 1000,
            easing: util.ease.easeInOutCubic,
            onComplete: animateLoadingCircle,
          }
      );
    }
  }

  animateLoadingCircle();
}

/**
 * Fades out a Fabric image object.
 *
 * @param canvasComponent - The canvas to work on
 * @param image - The Fabric image to fade out.
 * If undefined, the callback will be called directly
 * @param duration - The duration of the fade-out effect in milliseconds.
 */
export function fadeOutImage(canvasComponent: CanvasComponent, image: Image | undefined, duration = 400): Promise<void> {
  return new Promise((resolve) => {
    if (image) {
      image.animate({opacity: 0}, {
        duration: duration,
        onChange: canvasComponent.canvas.renderAll.bind(canvasComponent.canvas),
        onComplete: () => {
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
}

/**
 * Fades out multiple Fabric image objects.
 *
 * @param canvasComponent - The canvas to work on
 * @param images - The array of Fabric images to fade out.
 * @param duration - The duration of the fade-out effect in milliseconds.
 * @returns A promise that resolves when all animations are complete.
 */
export async function fadeOutImages(canvasComponent: CanvasComponent, images: (Image | undefined)[], duration = 400): Promise<void> {
  const promises = images.map(image => fadeOutImage(canvasComponent, image, duration));
  await Promise.all(promises);
}

/**
 * Fades in a Fabric image object.
 *
 * @param canvasComponent - The canvas to work on
 * @param image - The Fabric image to fade in with the target index and opacity to reach.
 * @param duration - The duration of the fade-in effect in milliseconds.
 */
export function fadeInImage(canvasComponent: CanvasComponent, image: [Image, index: number, opacity: number], duration = 400): Promise<void> {
  image[0].set({opacity: 0});
  return new Promise((resolve) => {
    image[0].animate({opacity: image[2]}, {
      duration: duration,
      onChange: canvasComponent.canvas.renderAll.bind(canvasComponent.canvas),
      onComplete: () => {
        resolve();
      }
    });
  });
}

/**
 * Fades in multiple Fabric image objects.
 *
 * @param canvasComponent - The canvas to work on
 * @param images - The array of Fabric image objects to fade in.
 * @param duration - The duration of the fade-in effect in milliseconds.
 * @returns A promise that resolves when all animations are complete.
 */
export async function fadeInImages(canvasComponent: CanvasComponent, images: [Image, index: number, opacity: number][], duration = 400): Promise<void> {
  const promises = images.map(image => fadeInImage(canvasComponent, image, duration));
  await Promise.all(promises);
}
