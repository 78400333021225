/// <reference path="./custom-window.d.ts" />
import * as THREE from 'three';
import {animateCamera, getIntersectedConstruction, getIntersectedObjects, getMousePosition} from "./floorplan/utils";
import {
  activateButton,
  hardcoded,
  inactivateButton,
  setInputText,
  setParagraphText,
  swallow,
  updateRoot
} from "./Utils";
import {createDoor, isDoor, manipulateDoor, operateDoor, removeDoor, showDoorHandle} from "./floorplan/doors";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {
  activateCamera,
  createCamera, hideCameraHandle, hideCameraSide,
  hideCameraTop,
  isCamera,
  manipulateCamera, operateCamera, removeCamera, showCameraHandle, showCameraSide,
  showCameraTop
} from "./floorplan/cameras";
import {
  Camera,
  Construction,
  Context,
  Door,
  Furniture,
  Meters,
  Meters2, numberToDirection,
  Reference,
  State,
  Wall,
  Window
} from "./floorplan/types";
import {
  createWall, finishWall, hideWallHandle, hideWallSide,
  hideWallTop,
  isWall,
  manipulateWall,
  removeWall, showWallHandle, showWallSide,
  showWallTop, updateWall
} from "./floorplan/walls";
import {createWindow, isWindow, manipulateWindow, removeWindow, showWindowHandle} from "./floorplan/windows";
import {
  createFurniture, hideFurnitureHandle, hideFurnitureSide,
  hideFurnitureTop,
  isFurniture, manipulateFurniture,
  removeFurniture, showFurnitureHandle, showFurnitureSide,
  showFurnitureTop
} from "./floorplan/furnitures";
import {computed, effect, signal, WriteSignal} from "@maverick-js/signals";
import {
  createReference,
  hideReferenceHandle, hideReferenceSide,
  hideReferenceTop, isReference, manipulateReference,
  removeReference, showReferenceHandle, showReferenceSide,
  showReferenceTop
} from "./floorplan/reference";
import {USDZLoader} from "three/examples/jsm/loaders/USDZLoader";
import {FileFormat} from "./floorplan/fileformat";
import {fileFormatV1} from "proplab-foundation-primitives";

window.PLFloorPlanEditorInstall = async function (id, project, reference, drawing, result, show, separator) {
  console.log('Floor Plan Editor install, project=' + project + ', reference=' + reference + ', drawing=' + drawing + ', result=' + result + ', show=' + show);

  const root = document.getElementById(id + separator + 'root') as HTMLElement;
  const drawingRoot = document.getElementById(id + separator + 'drawing-root');
  const resultRoot = document.getElementById(id + separator + 'result-root');
  const resultElement = document.getElementById(id + separator + 'result') as HTMLImageElement;

  const aspect = root.clientWidth / root.clientHeight;
  const cameraSize = 50;

  // State
  const $gridSize = signal(new Meters(2.0));
  const $snapSize = signal(new Meters(0.25));
  const $cameraZoom = signal(1);
  const $cameraPosition: WriteSignal<[number, number]> = signal([0, 0]);
  let state: State = {
    tool: 'selection',
    show: show,
    isSnapping: true,
    walls: [],
    furnitures: [],
    cameras: [],
    wallHeight: new Meters(3),
    wallThickness: Meters.fromCentimeters(30),
    doorThickness: Meters.fromCentimeters(7),
    doorHeight: new Meters(2),
    doorWidth: new Meters(0.8),
    windowHeight: new Meters(1.5),
    windowWidth: new Meters(1.5),
    windowOffset: new Meters(0.75),

    $handleSize: computed(() => 0.8 / $cameraZoom()),
    $gridSize: $gridSize,
    $snapSize: $snapSize,
    $scale: signal(1),
  }

  // Cameras
  const orthographicCamera = new THREE.OrthographicCamera(-cameraSize, cameraSize, cameraSize, -cameraSize, 1, 1000);

  // Floor & ceiling
  const geometry = new THREE.PlaneGeometry(1000, 1000); // Temporary size, will resize (or not)
  // const floorMaterial = new THREE.MeshStandardMaterial({color: 0x808080, roughness: 0.5, metalness: 0.0});
  const floorMaterial = new THREE.MeshBasicMaterial({color: 0x808080});
  const floor = new THREE.Mesh(geometry, floorMaterial);
  floor.position.set(0, 0, 0);
  floor.visible = false;
  floor.receiveShadow = true;
  floor.castShadow = false;
  const ceilingMaterial = new THREE.MeshBasicMaterial({color: 0xFFFFFF, side: THREE.DoubleSide});
  const ceiling = new THREE.Mesh(geometry, ceilingMaterial);
  ceiling.visible = false;
  ceiling.position.set(0, 0, state.wallHeight.toWorld(state));

  // Context
  const context: Context = {
    container: document.getElementById(id + separator + 'container') as HTMLElement,
    scene: new THREE.Scene(),
    aspect: aspect,
    orthographicCamera: orthographicCamera,
    activeCamera: orthographicCamera,
    renderer: new THREE.WebGLRenderer({antialias: true}),
    raycaster: new THREE.Raycaster(),
    floor: floor,
    ceiling: ceiling,
    gltfLoader: new GLTFLoader(),
    usdzLoader: new USDZLoader(),
    textureLoader: new THREE.TextureLoader(),

    panId(): string {
      return id + separator + 'zoom' + separator + 'pan';
    },
    selectionId(): string {
      return id + separator + 'tools' + separator + 'selection';
    },
    wallsSingleId(): string {
      return id + separator + 'tools' + separator + 'walls_single';
    },
    wallsContinuousId(): string {
      return id + separator + 'tools' + separator + 'walls_continuous';
    },
    constructionId(): string {
      return id + separator + 'inspector' + separator + 'construction';
    },
    lengthId(): string {
      return id + separator + 'inspector' + separator + 'length';
    },
  }

// Floor & ceiling
// context.scene.add(context.ceiling);
  context.scene.add(context.floor);

// Renderer & camera
  drawingRoot?.appendChild(context.renderer.domElement);
  context.renderer.setClearColor(0xFFFFFF, 1);
  context.renderer.shadowMap.enabled = true;
  context.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

// Zooming and panning
  let isMouseDown = false;
  let zoomInitial = 0.5;
  let externaZoomLevel = zoomInitial;
  let externalMove: [number, number] = [0, 0];
  let internalMove: [number, number] = [0, 0];
  let externalPrevious: [number, number] = [0, 0];
  let internalPrevious: [number, number] = [0, 0];

// Resizing
  function resize(root: HTMLElement) {
    const newWidth = root.clientWidth;
    const newHeight = root.clientHeight;
    context.aspect = newWidth / newHeight;
    if (aspect >= 1) { // wider than tall
      context.orthographicCamera.left = -cameraSize * aspect;
      context.orthographicCamera.right = cameraSize * aspect;
      context.orthographicCamera.top = cameraSize;
      context.orthographicCamera.bottom = -cameraSize;
    } else { // taller than wide
      context.orthographicCamera.left = -cameraSize;
      context.orthographicCamera.right = cameraSize;
      context.orthographicCamera.top = cameraSize / aspect;
      context.orthographicCamera.bottom = -cameraSize / aspect;
    }
    context.orthographicCamera.updateProjectionMatrix();
    context.renderer?.setSize(newWidth, newHeight);

    // Grid
    if (context.grid) context.scene.remove(context.grid);
    const area = 200; // Needs to be an even number larger than the visible area (Right now 133 units)
    context.grid = new THREE.GridHelper(area, area / state.$gridSize().meters / state.$scale(), 0x0000ff, 0x808080);
    context.grid.rotation.x = Math.PI / 2;
    context.scene.add(context.grid);
  }

// Images & Data
  let referenceImage: [string, string] | undefined = undefined;
  let drawingData: [string, FileFormat] | undefined = undefined;
  let resultImage: [string, string] | undefined = undefined;

// Lights
  const ambientLight = new THREE.AmbientLight(0x404040, 1); // Soft white light
  context.scene.add(ambientLight);
  const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1); // Sky color and ground color
  context.scene.add(hemisphereLight);

// Initial set-up
  startTool(context, state, 'selection');
  updateRoot(root, externaZoomLevel, externalMove, resize);
  setupInteractiveControls();
  setupKeyboardControls();
  referenceImage = await setReferenceImage(context, state, referenceImage, reference);
  drawingData = await setDrawingData(context, state, drawingData, drawing);
  resultImage = await setResultElement(resultElement, resultImage, result);
  updateDrawing(context, state, drawingRoot, resultRoot);
  inspectConstruction(context, state);

// Effects
  const effects = effect(() => {
    console.log('Floor Plan Editor effects, camera zoom: ' + $cameraZoom() + 'x, position: ' + $cameraPosition()[0] + ', ' + $cameraPosition()[1]);
    context.orthographicCamera.position.set($cameraPosition()[0], $cameraPosition()[1], 50);
    context.orthographicCamera.zoom = $cameraZoom();
    context.orthographicCamera.lookAt($cameraPosition()[0], $cameraPosition()[1], 0);
    context.orthographicCamera.up.set(0, 1, 0);
    context.orthographicCamera.updateProjectionMatrix();
    return () => {
    };
  });

// Render Loop
  function animate() {
    requestAnimationFrame(animate);
    context.renderer.render(context.scene, context.activeCamera);
  }

  animate(); // Start the rendering loop

  window.PLFloorPlanEditorUpdate = async function (id, reference, drawing, result, show) {
    console.log('Floor Plan Editor update, reference=' + reference + ', drawing=' + drawing + ', result=' + result + ', show=' + show);
    referenceImage = await setReferenceImage(context, state, referenceImage, reference);
    drawingData = await setDrawingData(context, state, drawingData, drawing);
    resultImage = await setResultElement(resultElement, resultImage, result);
    state.show = show;
    updateDrawing(context, state, drawingRoot, resultRoot);
  };

  window.PLFloorPlanEditorToggleDebug = async function (event) {
    if (context.orthographicCamera.position.z === 50) {
      showAllTops(context, state);
      showAllSides(context, state);
      const target = new THREE.Vector3(0, -50, 0);
      animateCamera(context, target, 2000);
    } else {
      showAllTops(context, state);
      hideAllSides(context, state);
      const target = new THREE.Vector3(0, 0, 50);
      animateCamera(context, target, 2000);
    }
    context.container?.focus(); // Always focus container to fix event handlers
  };

  window.PLFloorPlanEditorToggleTool = async function (event, tool) {
    if (state.tool !== tool) {
      state.tool = startTool(context, state, tool);
    } else {
      state.tool = stopTool(context, state);
    }
    context.container?.focus(); // Always focus container to fix event handlers
  };

  window.PLFloorPlanEditorAddConstruction = async function (event, type) {
    const selectedWall = isWall(state.selectedConstruction);
    if (type === 'door' && selectedWall) {
      createDoor(context, state, selectedWall, 0.5, 'right_down', state.doorWidth, state.doorHeight);
    } else if (type === 'window' && selectedWall) {
      createWindow(context, state, selectedWall, 0.5, state.windowOffset, state.windowWidth, state.windowHeight);
    } else if (type === 'camera' && state.lastMouse) {
      createCamera(context, state, state.lastMouse);
    }
    context.container?.focus(); // Always focus container to fix event handlers
  };

  window.PLFloorPlanEditorControlCamera = async function (event, operation) {
    if (state.show == 'camera1') {
      operateCamera(context, state, state.cameras[0], operation);
    }
  }

  window.PLFloorPlanEditorTogglePanning = function (event) {
    if (state.tool === 'pan') {
      state.tool = stopPanning(context);
    } else {
      state.tool = startPanning(context);
    }
    context.container?.focus(); // Always focus container to fix event handlers
  }

  window.PLFloorPlanEditorZoom = function (event, delta) {
    if (event.altKey) {
      let cameraZoom = $cameraZoom();
      cameraZoom *= 0.999 ** delta;
      if (cameraZoom > 20) cameraZoom = 20;
      if (cameraZoom < 0.01) cameraZoom = 0.01;
      $cameraZoom.set(cameraZoom);
    } else {
      externaZoomLevel *= 0.999 ** delta;
      if (externaZoomLevel > 20) externaZoomLevel = 20;
      if (externaZoomLevel < 0.01) externaZoomLevel = 0.01;
      updateRoot(root, externaZoomLevel, externalMove, resize);
    }
    context.container?.focus(); // Always focus container to fix event handlers
  };

  window.PLFloorPlanEditorZoomReset = function (event) {
    if (event.altKey) {
      $cameraZoom.set(1.0);
    } else {
      externaZoomLevel = zoomInitial;
      updateRoot(root, externaZoomLevel, externalMove, resize);
    }
    context.container?.focus(); // Always focus container to fix event handlers
  };

  window.PLFloorPlanEditorSave = async function (event) {
    const file = new FileFormat();

    state.walls.forEach(wall => {
      file.addWall(wall);
    });
    state.furnitures.forEach(furniture => {
      file.addFurniture(furniture);
    });
    state.cameras.forEach(camera => {
      file.addCamera(camera);
    });

    const jsonString = file.stringify();
    const formData = new FormData();
    const jsonBlob = new Blob([jsonString], {type: 'application/json'});
    formData.append('file', jsonBlob, 'canvas.json');
    formData.append('type', fileFormatV1);
    formData.append('project', project);
    if (drawingData) {
      formData.append('asset', drawingData[0]);
    }

    const response = await fetch('/floor-plan-editor-upload-floor-plan', {
      method: 'POST',
      body: formData
    });

    if (response.ok) {
      window.htmx.trigger(`#floor-plan-editor-refresh-${id}`, 'click');
    } else {
      const errorText = await response.text();
      alert(errorText);
    }

    context.container?.focus(); // Always focus container to fix event handlers
  };

  window.PLFloorPlanEditorGenerate = function (event) {
    const canvas = context.renderer.domElement;
    context.renderer.render(context.scene, context.activeCamera);
    canvas.toBlob(function (blob) {
      if (blob) {
        const formData = new FormData();
        formData.append('file', blob, 'canvas_image.png');
        formData.append('type', 'camera');
        formData.append('project', project);
        fetch('/floor-plan-editor-upload-camera', {
          method: 'POST',
          body: formData
        }).then(r => {
          window.htmx.trigger('#floor-plan-editor-generate-' + id, 'click');
        });
      }
    }, 'image/png');
    context.container?.focus(); // Always focus container to fix event handlers
  }

  function setupKeyboardControls() {
    // Panning & Walls
    context.container?.addEventListener('keydown', function (e) {
      const selectedWall = isWall(state.selectedConstruction);
      const selectedDoor = isDoor(state.selectedConstruction);
      const selectedWindow = isWindow(state.selectedConstruction);
      const selectedFurniture = isFurniture(state.selectedConstruction);
      const selectedCamera = isCamera(state.selectedConstruction);
      if (e.code === 'Escape' && (state.tool === 'walls_single' || state.tool === 'walls_continuous')) {
        state.tool = stopTool(context, state);
        swallow(e);
      } else if (e.code === 'Space') {
        state.tool = startPanning(context);
        swallow(e);
      } else if (e.code === 'Backspace' && selectedWall) {
        removeWall(context, state, selectedWall);
        swallow(e);
      } else if (e.code === 'Backspace' && selectedDoor) {
        removeDoor(context, selectedDoor);
        swallow(e);
      } else if (e.code === 'Backspace' && selectedWindow) {
        removeWindow(context, selectedWindow);
        swallow(e);
      } else if (e.code === 'Backspace' && selectedFurniture) {
        removeFurniture(context, state, selectedFurniture);
        swallow(e);
      } else if (e.code === 'Backspace' && selectedCamera) {
        removeCamera(context, selectedCamera);
        swallow(e);
      } else if (e.code === 'KeyW') {
        window.PLFloorPlanEditorToggleTool(e, 'walls_single');
        swallow(e);
      } else if (e.code === 'KeyE') {
        window.PLFloorPlanEditorToggleTool(e, 'walls_continuous');
        swallow(e);
      } else if (e.code === 'KeyD') {
        window.PLFloorPlanEditorAddConstruction(e, 'door');
        swallow(e);
      } else if (e.code === 'KeyF') {
        window.PLFloorPlanEditorAddConstruction(e, 'window');
        swallow(e);
      } else if (e.code === 'KeyC') {
        window.PLFloorPlanEditorAddConstruction(e, 'camera');
        swallow(e);
      } else if (e.code === 'Digit1' && state.lastMouse) {
        createFurniture(context, state, state.lastMouse, 0, '00001');
        swallow(e);
      } else if (e.code === 'Digit2' && state.lastMouse) {
        createFurniture(context, state, state.lastMouse, 0, '00002');
        swallow(e);
      } else if (e.code === 'Digit3' && state.lastMouse) {
        createFurniture(context, state, state.lastMouse, 0, '00003');
        swallow(e);
      } else if (e.code === 'ArrowLeft' && state.show == 'camera1') {
        window.PLFloorPlanEditorControlCamera(e, 'rotate_left');
        swallow(e);
      } else if (e.code === 'ArrowRight' && state.show == 'camera1') {
        window.PLFloorPlanEditorControlCamera(e, 'rotate_right');
        swallow(e);
      } else if (e.code === 'ArrowUp' && state.show == 'camera1') {
        window.PLFloorPlanEditorControlCamera(e, 'zoom_in');
        swallow(e);
      } else if (e.code === 'ArrowDown' && state.show == 'camera1') {
        window.PLFloorPlanEditorControlCamera(e, 'zoom_out');
        swallow(e);
      } else if (e.code === 'ArrowLeft' && selectedDoor) {
        operateDoor(context, state, selectedDoor, 'rotate_left');
        swallow(e);
      } else if (e.code === 'ArrowRight' && selectedDoor) {
        operateDoor(context, state, selectedDoor, 'rotate_right');
        swallow(e);
      } else if (e.code === 'ArrowUp' && selectedDoor) {
        operateDoor(context, state, selectedDoor, 'zoom_in');
        swallow(e);
      } else if (e.code === 'ArrowDown' && selectedDoor) {
        operateDoor(context, state, selectedDoor, 'zoom_out');
        swallow(e);
      }
    }, {capture: true});
    context.container?.addEventListener('keyup', function (e) {
      if (e.code === 'Space') {
        state.tool = stopPanning(context);
        swallow(e);
      }
    });

    // Copy and Paste
    /*    container?.addEventListener('keydown', async function (e) {
          if (e.code === 'KeyC' && (e.ctrlKey || e.metaKey)) { // Clone on ctrl+c
            copiedObject = await copyObject(drawingCanvas);
            swallow(e);
          } else if (e.code === 'KeyV' && (e.ctrlKey || e.metaKey)) { // Paste on ctrl+v
            pasteObject(drawingCanvas, copiedObject);
            swallow(e);
          } else if (e.code === 'KeyD' && (e.ctrlKey || e.metaKey)) { // Duplicate on ctrl+d
            pasteObject(drawingCanvas, await copyObject(drawingCanvas));
            swallow(e);
          }
        }); */

    // Keyboard movement
    /*    container?.addEventListener('keydown', function (e) {
          // set the step size for each arrow key press
          let step = 1;
          if (e.shiftKey) { // if shift key is down then increase step
            step = 20;
          }

          // get the active object - the one you want to move
          const activeObject = drawingCanvas.getActiveObject();

          if (!activeObject) return; // if no active object, do nothing

          switch (e.code) {
            case 'ArrowLeft': // left arrow key
              activeObject.left -= step;
              break;
            case 'ArrowUp': // up arrow key
              activeObject.top -= step;
              break;
            case 'ArrowRight': // right arrow key
              activeObject.left += step;
              break;
            case 'ArrowDown': // down arrow key
              activeObject.top += step;
              break;
          }

          activeObject.setCoords(); // necessary to keep track of object after moving it
          drawingCanvas.renderAll(); // re-render the canvas to see the result
        });*/
  }

  function setupInteractiveControls() {
    // Rotation snapping with shift
    /*drawingCanvas.on('object:rotating', function (opt) {
      if (opt.e.shiftKey === true) { // if shift key is down then snap rotation
        opt.target.snapAngle = 45;
      } else {
        opt.target.snapAngle = undefined;
      }
    });*/

    // Drawing
    drawingRoot?.addEventListener('mousedown', function (event) {
      state = onMouseDown(context, state, event);
    });

    drawingRoot?.addEventListener('mousemove', function (event) {
      state = onMouseMove(context, state, event);
    });

    drawingRoot?.addEventListener('mouseup', function (event) {
      state = onMouseUp(context, state, event);
    });

    // Zooming
    context.container.addEventListener('wheel', function (e) {
      if (e.ctrlKey || e.metaKey) {
        window.PLFloorPlanEditorZoom(e, e.deltaY);
      }
    });

    context.container.onmousedown = function (e) {
      if (state.tool === 'pan') {
        isMouseDown = true;
        if (e.altKey) {
          internalPrevious = [e.clientX, e.clientY];
        } else {
          externalPrevious = [e.clientX, e.clientY];
        }
        swallow(e);
      }
    };

    context.container.onmouseup = function () {
      isMouseDown = false;
    };

    context.container.onmousemove = function (e) {
      if (state.tool === 'pan' && isMouseDown) {
        if (e.altKey) {
          const cameraZoom = $cameraZoom();
          const panSpeed = 0.2 / cameraZoom;
          const cameraPosition = $cameraPosition();
          internalMove = [
            (e.clientX - internalPrevious[0]) * panSpeed,
            (e.clientY - internalPrevious[1]) * panSpeed
          ];
          internalPrevious = [e.clientX, e.clientY];
          $cameraPosition.set([cameraPosition[0] - internalMove[0], cameraPosition[1] + internalMove[1]]);
          swallow(e);
        } else {
          externalMove = [
            externalMove[0] + ((e.clientX - externalPrevious[0]) / externaZoomLevel),
            externalMove[1] + ((e.clientY - externalPrevious[1]) / externaZoomLevel)
          ];
          externalPrevious = [e.clientX, e.clientY];
          updateRoot(root, externaZoomLevel, externalMove, resize);
          swallow(e);
        }
      }
    };
  }
};

async function setReferenceImage(context: Context, state: State, current: [string, string] | undefined, image: [string, string] | undefined): Promise<[string, string] | undefined> {
  const [id1] = current ?? [];
  const [id2] = image ?? [];
  if (id1 !== id2) {
    if (state.reference) {
      removeReference(context, state.reference);
    }
    if (image && image[1] && image[1] !== '') {
      createReference(context, state, new Meters2(0, 0), image[1]);
      return [image[0], ''];
    } else {
      return undefined;
    }
  } else {
    return current;
  }
}

async function setDrawingData(context: Context, state: State, current: [string, FileFormat] | undefined, data: [string, string] | undefined): Promise<[string, FileFormat] | undefined> {
  const [id1] = current ?? [];
  const [id2] = data ?? [];
  if (id1 !== id2) {
    if (data && data[1] && data[1] !== '') {
      const response: any = await fetch(data[1]);
      if (!response.ok) {
        throw new Error(`Failed to fetch data from ${data[1]}: ${response.statusText}`);
      }

      const json = await response.json();
      const file = new FileFormat()
      file.assign(json);

      file.walls().forEach(w => {
        const start = new Meters2(w.sx, w.sy);
        const end = new Meters2(w.ex, w.ey);
        const wall = createWall(context, state, start, end);
        finishWall(context, state, wall);

        w.ds?.forEach(d => {
          createDoor(context, state, wall, d.wo, numberToDirection(d.di), new Meters(d.wt), new Meters(d.ht));
        });
        w.ws?.forEach(w => {
          createWindow(context, state, wall, w.wo, new Meters(w.fo), new Meters(w.wt), new Meters(w.ht));
        });
      });
      file.furnitures()?.forEach(f => {
        createFurniture(context, state, new Meters2(f.cx, f.cy), f.an, f.kn);
      });
      file.cameras()?.forEach(c => {
        createCamera(context, state, new Meters2(c.cx, c.cy));
      });

      return [data[0], file];
    } else {
      state.walls.forEach((wall) => {
        removeWall(context, state, wall);
      });
      state.furnitures.forEach((furniture) => {
        removeFurniture(context, state, furniture);
      });
      return undefined;
    }
  } else {
    return current;
  }
}

async function setResultElement(resultElement: HTMLImageElement, current: [string, string] | undefined, image: [string, string] | undefined): Promise<[string, string] | undefined> {
  const [id1] = current ?? [];
  const [id2] = image ?? [];
  if (id1 !== id2) {
    if (current) {
      resultElement.src = "";
    }
    if (image && image[1] && image[1] !== '') {
      resultElement.src = image[1];
      return image;
    } else {
      return undefined;
    }
  } else {
    return current;
  }
}

function showAllTops(context: Context, state: State) {
  state.walls.forEach(it => showWallTop(context, it));
  state.furnitures.forEach(it => showFurnitureTop(context, it));
  state.cameras.forEach(it => showCameraTop(context, it));
  if (state.reference) showReferenceTop(context, state.reference);
}

function hideAllTops(context: Context, state: State) {
  state.walls.forEach(it => hideWallTop(context, it));
  state.furnitures.forEach(it => hideFurnitureTop(context, it));
  state.cameras.forEach(it => hideCameraTop(context, it));
  if (state.reference) hideReferenceTop(context, state.reference);
}

function showAllSides(context: Context, state: State) {
  state.walls.forEach(it => showWallSide(context, it));
  state.furnitures.forEach(it => showFurnitureSide(context, it));
  state.cameras.forEach(it => showCameraSide(context, it));
  if (state.reference) showReferenceSide(context, state.reference);
}

function hideAllSides(context: Context, state: State) {
  state.walls.forEach(it => hideWallSide(context, it));
  state.furnitures.forEach(it => hideFurnitureSide(context, it));
  state.cameras.forEach(it => hideCameraSide(context, it));
  if (state.reference) hideReferenceSide(context, state.reference);
}

function hideAllHandles(context: Context, state: State) {
  state.walls.forEach(it => hideWallHandle(context, it));
  state.furnitures.forEach(it => hideFurnitureHandle(context, it));
  state.cameras.forEach(it => hideCameraHandle(context, it));
  if (state.reference) hideReferenceHandle(context, state.reference);
}

function onMouseDown(context: Context, state: State, event: MouseEvent): State {
  const selectedWall = isWall(state.selectedConstruction);
  state.initialMouse = getMousePosition(context, state, event);
  state.lastMouse = state.initialMouse;

  if (state.tool === 'selection') {
    swallow(event);
    // Check intersection with walls, doors or handles
    let intersect = getIntersectedConstruction(context, event, context.scene.children);
    const wall = intersect?.object?.userData?.isWall ? intersect?.object.userData.construction as Wall : undefined;
    const door = intersect?.object?.userData?.isDoor ? intersect?.object.userData.construction as Door : undefined;
    const window = intersect?.object?.userData?.isWindow ? intersect?.object.userData.construction as Window : undefined;
    const furniture = intersect?.object?.userData?.isFurniture ? intersect?.object.userData.construction as Furniture : undefined;
    const camera = intersect?.object?.userData?.isCamera ? intersect?.object.userData.construction as Camera : undefined;
    const reference = intersect?.object?.userData?.isReference ? intersect?.object.userData.construction as Reference : undefined;

    if (reference && state.show === 'reference') {
      if (intersect!.object === reference.topLeft) {
        state.isManipulatingConstruction = 'start';
      } else if (intersect!.object === reference.bottomLeft) {
        state.isManipulatingConstruction = 'end';
      } else if (intersect!.object === reference.topRight) {
        state.isManipulatingConstruction = 'start';
      } else if (intersect!.object === reference.bottomRight) {
        state.isManipulatingConstruction = 'end';
      } else {
        state.isManipulatingConstruction = 'move';
      }
      selectConstruction(context, state, reference);
    } else if (wall && state.show === 'drawing') {
      if (intersect!.object === wall.start) {
        state.isManipulatingConstruction = 'start';
      } else if (intersect!.object === wall.end) {
        state.isManipulatingConstruction = 'end';
      } else {
        state.isManipulatingConstruction = 'move';
      }
      selectConstruction(context, state, wall);
    } else if (door && state.show === 'drawing') {
      state.isManipulatingConstruction = 'move';
      selectConstruction(context, state, door);
    } else if (window && state.show === 'drawing') {
      if (intersect!.object === window.start) {
        state.isManipulatingConstruction = 'start';
      } else if (intersect!.object === window.end) {
        state.isManipulatingConstruction = 'end';
      } else {
        state.isManipulatingConstruction = 'move';
      }
      selectConstruction(context, state, window);
    } else if (furniture && state.show === 'drawing') {
      if (intersect!.object === furniture.rotate) {
        state.isManipulatingConstruction = 'rotate';
      } else {
        state.isManipulatingConstruction = 'move';
      }
      selectConstruction(context, state, furniture);
    } else if (camera && state.show === 'drawing') {
      state.isManipulatingConstruction = 'move';
      selectConstruction(context, state, camera);
    } else if (state.show === 'drawing') {
      state.isManipulatingConstruction = undefined;
      selectConstruction(context, state, undefined);
    }
  } else if ((state.tool === 'walls_single' || state.tool === 'walls_continuous') && state.isManipulatingConstruction == 'track' && selectedWall) {
    swallow(event);
    // Second click: finish the current wall and start a new one
    finishWall(context, state, selectedWall);

    if (state.tool === 'walls_continuous') {
      const newStartPoint = new Meters2(selectedWall.endX[1], selectedWall.endY[1]);
      state.isManipulatingConstruction = 'track';
      state.unfinishedWall = createWall(context, state, newStartPoint, newStartPoint)
      selectConstruction(context, state, state.unfinishedWall);
    } else {
      state.isManipulatingConstruction = undefined;
      selectConstruction(context, state, selectedWall);
      state.unfinishedWall = undefined;
    }
  } else if (state.tool === 'walls_single' || state.tool === 'walls_continuous') {
    swallow(event);
    // First click: start a new wall
    state.unfinishedWall = createWall(context, state, state.lastMouse, state.lastMouse);
    state.isManipulatingConstruction = 'track';
    selectConstruction(context, state, state.unfinishedWall);
  }

  return state;
}

function onMouseMove(context: Context, state: State, event: MouseEvent): State {
  const selectedWall = isWall(state.selectedConstruction);
  const selectedDoor = isDoor(state.selectedConstruction);
  const selectedWindow = isWindow(state.selectedConstruction);
  const selectedFurniture = isFurniture(state.selectedConstruction);
  const selectedCamera = isCamera(state.selectedConstruction);
  const selectedReference = isReference(state.selectedConstruction);

  const mouse = getMousePosition(context, state, event);

  if (state.isManipulatingConstruction && selectedWall) {
    swallow(event);
    manipulateWall(context, state, mouse, false, selectedWall);
    inspectConstruction(context, state);
  } else if (state.isManipulatingConstruction && selectedDoor) {
    swallow(event);
    manipulateDoor(context, state, mouse, false, selectedDoor);
  } else if (state.isManipulatingConstruction && selectedWindow) {
    swallow(event);
    manipulateWindow(context, state, mouse, false, selectedWindow);
  } else if (state.isManipulatingConstruction && selectedFurniture) {
    swallow(event);
    manipulateFurniture(context, state, mouse, false, selectedFurniture);
  } else if (state.isManipulatingConstruction && selectedCamera) {
    swallow(event);
    manipulateCamera(context, state, mouse, false, selectedCamera);
  } else if (state.isManipulatingConstruction && selectedReference) {
    swallow(event);
    manipulateReference(context, state, mouse, false, selectedReference);
  }

  state.lastMouse = mouse;

  return state;
}

function onMouseUp(context: Context, state: State, event: MouseEvent): State {
  const selectedWall = isWall(state.selectedConstruction);
  const selectedDoor = isDoor(state.selectedConstruction);
  const selectedWindow = isWindow(state.selectedConstruction);
  const selectedFurniture = isFurniture(state.selectedConstruction);
  const selectedCamera = isCamera(state.selectedConstruction);
  const selectedReference = isReference(state.selectedConstruction);

  const mouse = getMousePosition(context, state, event);

  if (state.isManipulatingConstruction && selectedWall) {
    swallow(event);
    manipulateWall(context, state, mouse, true, selectedWall);
    state.isManipulatingConstruction = state.isManipulatingConstruction === 'track' ? 'track' : undefined;
  } else if (state.isManipulatingConstruction && selectedDoor) {
    swallow(event);
    manipulateDoor(context, state, mouse, true, selectedDoor);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction && selectedWindow) {
    swallow(event);
    manipulateWindow(context, state, mouse, true, selectedWindow);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction && selectedFurniture) {
    swallow(event);
    manipulateFurniture(context, state, mouse, true, selectedFurniture);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction && selectedCamera) {
    swallow(event);
    manipulateCamera(context, state, mouse, true, selectedCamera);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction && selectedReference) {
    swallow(event);
    manipulateReference(context, state, mouse, true, selectedReference);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction) {
    swallow(event);
    state.isManipulatingConstruction = undefined;
  }

  state.initialMouse = undefined;
  state.lastMouse = undefined;

  return state;
}

function selectConstruction(context: Context, state: State, construction: Construction | undefined) {
  hideAllHandles(context, state);
  state.selectedConstruction = construction

  const selectedWall = isWall(state.selectedConstruction);
  const selectedDoor = isDoor(state.selectedConstruction);
  const selectedWindow = isWindow(state.selectedConstruction);
  const selectedFurniture = isFurniture(state.selectedConstruction);
  const selectedCamera = isCamera(state.selectedConstruction);
  const selectedReference = isReference(state.selectedConstruction);

  if (selectedWall) showWallHandle(context, selectedWall);
  if (selectedDoor) showDoorHandle(context, selectedDoor);
  if (selectedWindow) showWindowHandle(context, selectedWindow);
  if (selectedFurniture) showFurnitureHandle(context, selectedFurniture);
  if (selectedCamera) showCameraHandle(context, selectedCamera);
  if (selectedReference) showReferenceHandle(context, selectedReference);

  inspectConstruction(context, state);
}

function inspectConstruction(context: Context, state: State) {
  const selectedWall = isWall(state.selectedConstruction);
  if (selectedWall) {
    setParagraphText(context.constructionId(), hardcoded("Vägg"));
    setInputText(context.lengthId(), selectedWall.length.meters.toPrecision(5));
  } else {
    setParagraphText(context.constructionId(), hardcoded("Planlösning"));
    setInputText(context.lengthId(), '');
  }
}

function startTool(context: Context, state: State, tool: Tool): Tool {
  stopTool(context, state);

  if (tool === 'walls_single') {
    context.container.style.cursor = 'crosshair';
    activateButton(context.wallsSingleId());
    inactivateButton(context.selectionId());
    inactivateButton(context.wallsContinuousId());
    return 'walls_single';
  } else if (tool === 'walls_continuous') {
    context.container.style.cursor = 'crosshair';
    activateButton(context.wallsContinuousId());
    inactivateButton(context.selectionId());
    inactivateButton(context.wallsSingleId());
    return 'walls_continuous';
  } else {
    context.container.style.cursor = 'default';
    activateButton(context.selectionId());
    inactivateButton(context.wallsSingleId());
    inactivateButton(context.wallsContinuousId());
    return 'selection';
  }
}

function stopTool(context: Context, state: State): Tool {
  context.container.style.cursor = 'default';

  // Remove unfinished wall
  if ((state.tool == 'walls_single' || state.tool == 'walls_continuous') && state.unfinishedWall) {
    removeWall(context, state, state.unfinishedWall);
    state.unfinishedWall = undefined;
    const lastWall = state.walls[state.walls.length - 1];
    if (lastWall) selectConstruction(context, state, lastWall);
  }
  state.isManipulatingConstruction = undefined;

  activateButton(context.selectionId());
  inactivateButton(context.wallsSingleId());
  inactivateButton(context.wallsContinuousId());
  return 'selection';
}

function startPanning(context: Context): Tool {
  activateButton(context.panId());
  context.container.style.cursor = 'grab';
  return 'pan';
}

function stopPanning(context: Context): Tool {
  inactivateButton(context.panId());
  context.container.style.cursor = 'default';
  return 'selection';
}

function showOrthographicCamera(context: Context, state: State) {
  showAllTops(context, state);
  if (context.grid) context.grid.visible = true;
  context.floor.visible = false;
  context.ceiling.visible = false;
  context.activeCamera = context.orthographicCamera;
}

function showPerspectiveCamera(context: Context, state: State, camera: Camera) {
  if (camera.perspectiveCamera) {
    hideAllTops(context, state);
    hideAllHandles(context, state);
    if (context.grid) context.grid.visible = false;
    activateCamera(context, state, camera);
    context.floor.visible = true;
    context.ceiling.visible = true;
    context.activeCamera = camera.perspectiveCamera;
  }
}

function updateDrawing(context: Context, state: State, drawingRoot: HTMLElement | null, resultRoot: HTMLElement | null) {
  const showDrawing = state.show === 'reference' || state.show === 'drawing' || state.show === 'camera1'
  const showResult1 = state.show === 'result1'

  if (drawingRoot) {
    drawingRoot.style.display = showDrawing ? 'block' : 'none';
  }
  if (resultRoot) {
    resultRoot.style.display = showResult1 ? 'block' : 'none';
  }

  // Toggle selection
  if (state.show === 'reference' && !isReference(state.selectedConstruction) && state.reference) {
    selectConstruction(context, state, state.reference);
  } else if (state.show === 'drawing' && isReference(state.selectedConstruction)) {
    selectConstruction(context, state, undefined);
  }

  if (state.show === 'camera1') {
    hideAllTops(context, state);
    showAllSides(context, state);
    showPerspectiveCamera(context, state, state.cameras[0]);
  } else {
    showAllTops(context, state);
    hideAllSides(context, state);
    showOrthographicCamera(context, state);
  }

  context.container?.focus(); // Always focus container to fix event handlers
}

console.log('PLFloorPlanEditor2 has been installed');
