import * as THREE from "three";
import {v4} from "uuid";
import {Construction, Context, Meters, Meters2, Reference, State} from "./types";
import {createHandle, disposeAll, getMousePosition} from "./utils";

export async function createReference(context: Context, state: State, center: Meters2, image: string): Promise<Reference> {
  const reference: Reference = {
    type: 'reference',
    id: v4(),
    centerX: center.x,
    centerY: center.y,
    paddingX: 0,
    paddingY: 0,
    height: new Meters(100),
    width: new Meters(100),
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    thickness: Meters.zero,
  };

  // Create an image
  reference.top = await loadImage(context, state, reference, image);
  reference.top[0].position.set(0, 0, -1);
  context.scene.add(reference.top[0]);

  const halfWidth = reference.height.toWorld(state) / 2;
  const halfHeight = reference.height.toWorld(state) / 2;

  // Create handles for resizing
  reference.topLeft = createHandle(context, state, (handle) => {
    handle.position.set(reference.centerX.toWorld(state) - halfWidth, reference.centerY.toWorld(state) + halfHeight, 0.1);
    handle.userData.isReference = true;
    handle.userData.construction = reference;
    handle.userData.isReferenceTopLeft = true;
  });

  reference.bottomLeft = createHandle(context, state, (handle) => {
    handle.position.set(reference.centerX.toWorld(state) - halfWidth, reference.centerY.toWorld(state) - halfHeight, 0.1);
    handle.userData.isReference = true;
    handle.userData.construction = reference;
    handle.userData.isReferenceBottomLeft = true;
  });

  reference.topRight = createHandle(context, state, (handle) => {
    handle.position.set(reference.centerX.toWorld(state) + halfWidth, reference.centerY.toWorld(state) + halfHeight, 0.1);
    handle.userData.isReference = true;
    handle.userData.construction = reference;
    handle.userData.isReferenceTopRight = true;
  });

  reference.bottomRight = createHandle(context, state, (handle) => {
    handle.position.set(reference.centerX.toWorld(state) + halfWidth, reference.centerY.toWorld(state) - halfHeight, 0.1);
    handle.userData.isReference = true;
    handle.userData.construction = reference;
    handle.userData.isReferenceBottomRight = true;
  });

  state.reference = reference;

  return reference;
}

export function updateReference(context: Context, state: State, reference: Reference) {
  const halfWidth = reference.height.toWorld(state) * reference.scaleX / 2;
  const halfHeight = reference.height.toWorld(state) * reference.scaleY / 2;
  if (reference.top) {
    reference.top[0].position.set(reference.centerX.toWorld(state), reference.centerY.toWorld(state), reference.top[0].position.z);
    reference.top[0].scale.set(reference.scaleX, reference.scaleY, 1);
  }
  if (reference.topLeft) {
    reference.topLeft.position.set(reference.centerX.toWorld(state) - halfWidth, reference.centerY.toWorld(state) + halfHeight, reference.topLeft.position.z);
  }
  if (reference.bottomLeft) {
    reference.bottomLeft.position.set(reference.centerX.toWorld(state) - halfWidth, reference.centerY.toWorld(state) - halfHeight, reference.bottomLeft.position.z);
  }
  if (reference.topRight) {
    reference.topRight.position.set(reference.centerX.toWorld(state) + halfWidth, reference.centerY.toWorld(state) + halfHeight, reference.topRight.position.z);
  }
  if (reference.bottomRight) {
    reference.bottomRight.position.set(reference.centerX.toWorld(state) + halfWidth, reference.centerY.toWorld(state) - halfHeight, reference.bottomRight.position.z);
  }
}

export function manipulateReference(context: Context, state: State, mouse: Meters2, isFinished: boolean, reference: Reference) {
  if (!isFinished && state.lastMouse) {
    if (state.isManipulatingConstruction === 'move') {
      const delta = mouse.subtract(state.lastMouse);

      reference.centerX = reference.centerX.add(delta.x);
      reference.centerY = reference.centerY.add(delta.y);

      updateReference(context, state, reference);
    } else if (state.isManipulatingConstruction === 'start') {
      const delta = mouse.subtract(state.lastMouse);

      reference.scaleY = reference.scaleY + delta.y.toWorld(state) * 2 / reference.height.toWorld(state);
      const aspectRatio = reference.width.divide(reference.height).toWorld(state);
      reference.scaleX = reference.scaleY * aspectRatio;

      updateReference(context, state, reference);
    } else if (state.isManipulatingConstruction === 'end') {
      const delta = mouse.subtract(state.lastMouse);

      reference.scaleY = reference.scaleY - delta.y.toWorld(state) * 2 / reference.height.toWorld(state);
      const aspectRatio = reference.width.divide(reference.height).toWorld(state);
      reference.scaleX = reference.scaleY * aspectRatio;

      updateReference(context, state, reference);
    }
  }
}

export function removeReference(context: Context, reference: Reference) {
  if (reference.side) reference.side = disposeAll(context, reference.side[0]);
  if (reference.top) reference.top = disposeAll(context, reference.top[0]);
  if (reference.topLeft) reference.topLeft = disposeAll(context, reference.topLeft);
  if (reference.bottomLeft) reference.bottomLeft = disposeAll(context, reference.bottomLeft);
  if (reference.topRight) reference.topRight = disposeAll(context, reference.topRight);
  if (reference.bottomRight) reference.bottomRight = disposeAll(context, reference.bottomRight);
}

export function showReferenceTop(context: Context, reference: Reference) {
  if (reference.top) reference.top[0].visible = true;
}

export function hideReferenceTop(context: Context, reference: Reference) {
  if (reference.top) reference.top[0].visible = false;
}

export function showReferenceSide(context: Context, reference: Reference) {
  if (reference.side) reference.side[0].visible = true;
}

export function hideReferenceSide(context: Context, reference: Reference) {
  if (reference.side) reference.side[0].visible = false;
}

export function showReferenceHandle(context: Context, reference: Reference) {
  if (reference.topLeft) reference.topLeft.visible = true;
  if (reference.bottomLeft) reference.bottomLeft.visible = true;
  if (reference.topRight) reference.topRight.visible = true;
  if (reference.bottomRight) reference.bottomRight.visible = true;
}

export function hideReferenceHandle(context: Context, reference: Reference) {
  if (reference.topLeft) reference.topLeft.visible = false;
  if (reference.bottomLeft) reference.bottomLeft.visible = false;
  if (reference.topRight) reference.topRight.visible = false;
  if (reference.bottomRight) reference.bottomRight.visible = false;
}

export function isReference(construction: Construction | undefined): Reference | undefined {
  if (construction?.type === 'reference') {
    return construction as Reference;
  }
  return undefined;
}

async function loadImage(context: Context, state: State, reference: Reference, image: string): Promise<[THREE.Object3D, THREE.Mesh[]]> {
  return new Promise((resolve, reject) => {
    const texture = context.textureLoader.load(image, () => {
        const material = new THREE.MeshBasicMaterial({map: texture});
        const geometry = new THREE.PlaneGeometry(reference.width.toWorld(state), reference.height.toWorld(state));
        const image = new THREE.Mesh(geometry, material);
        image.userData.isReference = true;
        image.userData.construction = reference;
        image.userData.isReferencePart = true;

        // Resolve the promise with the image wrapped in a group
        const group = new THREE.Group();
        group.add(image);
        resolve([group, [image]]);
      },
      undefined,
      function (error) {
        console.error('An error occurred while loading the image', error);
        reject(error);
      }
    );
  });
}
