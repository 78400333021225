/// <reference path="./custom-window.d.ts" />
import {Toast} from "bootstrap";

window.PLToastShow = function (id, separator) {
  console.log('Toast show, id=' + id);

  const toastElement = document.getElementById(id);
  if (toastElement) {
    const toastBootstrap = Toast.getOrCreateInstance(toastElement);
    toastBootstrap.show();
  }
}

console.log('PLToast has been installed');
