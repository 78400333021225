import * as THREE from "three";
import {v4} from "uuid";
import {Construction, Context, Furniture, Meters, Meters2, Reference, State} from "./types";
import {createHandle, disposeAll} from "./utils";

export async function createFurniture(context: Context, state: State, center: Meters2, angle: number, kind: string): Promise<Furniture> {
  const thickness = state.wallThickness;

  const furniture: Furniture = {
    type: 'furniture',
    id: v4(),
    kind: kind,
    centerX: center.x,
    centerY: center.y,
    paddingX: 0,
    paddingY: 0,
    height: Meters.zero, // TODO: Set height based on furniture size
    angle: angle,
    width: Meters.zero, // TODO: Set width based on furniture size
    thickness: new Meters(0.8),
  };

  const lookup: { [key: string]: [string, string] } = {
    '00001': ["sofa", "glb"],
    '00002': ["desk", "glb"],
    '00003': ["desk-chair", "glb"],
  };
  const model = lookup[kind] || ["sofa", "glb"];

  // Create a furniture side
  furniture.side = await loadSide(context, state, furniture, model);
  furniture.side[0].position.set(furniture.centerX.toWorld(state), furniture.centerY.toWorld(state), 0);
  furniture.side[0].rotation.z = furniture.angle;
  furniture.side[0].scale.set(state.$scale(), state.$scale(), state.$scale());
  furniture.side[0].visible = false;
  context.scene.add(furniture.side[0]);

  const boundingBox = new THREE.Box3().setFromObject(furniture.side[1][0]);
  const size = new THREE.Vector3();
  boundingBox.getSize(size);
  furniture.width = new Meters(size.x);
  furniture.height = new Meters(size.y);

  // Create furniture 2D top-down representation slightly above the furniture height
  furniture.top = await loadTop(context, state, furniture, model[0]);
  furniture.top[0].position.set(furniture.centerX.toWorld(state), furniture.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.2); // slightly above the wall
  furniture.top[0].rotation.z = furniture.angle;
  context.scene.add(furniture.top[0]);

  // Create handles for rotating
  furniture.rotate = createHandle(context, state, (handle) => {
    const adjustedAngle = furniture.angle - (Math.PI / 2); // Offset 90 degrees in radians
    const offsetX = Math.cos(adjustedAngle) * furniture.thickness.meters;
    const offsetY = Math.sin(adjustedAngle) * furniture.thickness.meters;
    handle.position.set(furniture.centerX.add(offsetX).toWorld(state), furniture.centerY.add(offsetY).toWorld(state), 0.2); // slightly above the furniture
    handle.userData.isFurniture = true;
    handle.userData.construction = furniture;
    handle.userData.isFurnitureStart = true;
  });

  state.furnitures.push(furniture);

  return furniture;
}

export function updateFurniture(context: Context, state: State, furniture: Furniture) {
  if (furniture.side) {
    furniture.side[0].position.set(furniture.centerX.toWorld(state), furniture.centerY.toWorld(state), furniture.side[0].position.z);
    furniture.side[0].rotation.z = furniture.angle;
  }
  if (furniture.top) {
    furniture.top[0].position.set(furniture.centerX.toWorld(state), furniture.centerY.toWorld(state), furniture.top[0].position.z);
    furniture.top[0].rotation.z = furniture.angle;
  }
  if (furniture.rotate) {
    const adjustedAngle = furniture.angle - (Math.PI / 2); // Offset 90 degrees in radians
    const offsetX = Math.cos(adjustedAngle) * furniture.thickness.meters;
    const offsetY = Math.sin(adjustedAngle) * furniture.thickness.meters;
    furniture.rotate.position.set(furniture.centerX.add(offsetX).toWorld(state), furniture.centerY.add(offsetY).toWorld(state), furniture.rotate.position.z);
  }
}

export function removeFurniture(context: Context, state: State, furniture: Furniture) {
  if (furniture.side) furniture.side = disposeAll(context, furniture.side[0]);
  if (furniture.top) furniture.top = disposeAll(context, furniture.top[0]);
  if (furniture.rotate) furniture.rotate = disposeAll(context, furniture.rotate);

  state.furnitures = state.furnitures.filter(f => f.id !== furniture.id);
}

export function manipulateFurniture(context: Context, state: State, mouse: Meters2, isFinished: boolean, furniture: Furniture) {
  if (!isFinished && state.lastMouse && state.initialMouse) {
    if (state.isManipulatingConstruction === 'move') {
      const delta = mouse.subtract(state.lastMouse);

      furniture.centerX = furniture.centerX.add(delta.x);
      furniture.centerY = furniture.centerY.add(delta.y);
    } else if (state.isManipulatingConstruction === 'rotate') {
      if (!state.initialAngle) state.initialAngle = furniture.angle;

      // Calculate the angle from the furniture's center to the initial mouse position
      const initialDirection = state.initialMouse.subtract(furniture.centerX, furniture.centerY);
      const initialAngle = initialDirection.angle;

      // Calculate the angle from the furniture's center to the current mouse position
      const currentDirection = mouse.subtract(furniture.centerX, furniture.centerY);
      const currentAngle = currentDirection.angle;

      // Calculate the difference in angle
      const angleDelta = currentAngle - initialAngle;

      // Update the furniture's angle relative to its initial angle
      furniture.angle = state.initialAngle + angleDelta;
    }

    updateFurniture(context, state, furniture);
  } else if (isFinished) {
    state.initialAngle = undefined;
  }
}

export function showFurnitureTop(context: Context, furniture: Furniture) {
  if (furniture.top) furniture.top[0].visible = true;
}

export function hideFurnitureTop(context: Context, furniture: Furniture) {
  if (furniture.top) furniture.top[0].visible = false;
}

export function showFurnitureSide(context: Context, furniture: Furniture) {
  if (furniture.side) furniture.side[0].visible = true;
}

export function hideFurnitureSide(context: Context, furniture: Furniture) {
  if (furniture.side) furniture.side[0].visible = false;
}

export function showFurnitureHandle(context: Context, furniture: Furniture) {
  if (furniture.rotate) furniture.rotate.visible = true;
}

export function hideFurnitureHandle(context: Context, furniture: Furniture) {
  if (furniture.rotate) furniture.rotate.visible = false;
}

export function isFurniture(construction: Construction | undefined): Furniture | undefined {
  if (construction?.type === 'furniture') {
    return construction as Furniture;
  }
  return undefined;
}

async function loadSide(context: Context, state: State, furniture: Furniture, model: [string, string]): Promise<[THREE.Object3D, THREE.Mesh[], undefined]> {
  return new Promise((resolve, reject) => {

    function processModel(model: THREE.Object3D): [THREE.Object3D, THREE.Mesh[]] {
      model.rotation.x = Math.PI / 2; // Rotate to top-down

      let meshes: THREE.Mesh[] = [];
      // Apply solid light gray material to each mesh in the model
      model.traverse((child: THREE.Object3D) => {
        const mesh = child as THREE.Mesh;
        if (mesh) {
          meshes.push(mesh);
          mesh.userData.isFurniture = true;
          mesh.userData.construction = furniture;
          mesh.userData.isFurniturePart = true;

          console.log('Process mesh: ' + mesh.name)

          mesh.material = new THREE.MeshStandardMaterial({
            map: null,
            color: new THREE.Color(0x808080),
            roughness: 0.6,
            metalness: 0.2
          });
          /*
          mesh.material = new THREE.MeshBasicMaterial({
            map: null,
            color: new THREE.Color(0x808080),
          });*/
          child.castShadow = false;
          child.receiveShadow = false;
        }
      });
      return [model, meshes];
    }

    if (model[1] === 'glb') {
      context.gltfLoader.load(
        `/assets/3d/${model[0]}-side.glb`,
        function (gltf) {
          const [model, meshes] = processModel(gltf.scene);

          // Resolve the promise with the model wrapped in a group
          const group = new THREE.Group();
          group.add(model);
          resolve([group, meshes, undefined]);
        },
        undefined,
        function (error) {
          console.error('An error occurred while loading the glb model', error);
          reject(error);
        }
      );
    } else if (model[1] === 'usdz') {
      context.usdzLoader.load(
        `/assets/3d/${model[0]}-side.usdz`,
        function (scene) {
          const [model, meshes] = processModel(scene);

          // Resolve the promise with the model wrapped in a group
          const group = new THREE.Group();
          group.add(model);
          resolve([group, meshes, undefined]);
        },
        undefined,
        function (error) {
          console.error('An error occurred while loading the usdz model', error);
          reject(error);
        }
      );
    } else {
      reject("Unknown format");
    }
  });
}

async function loadTop(context: Context, state: State, furniture: Furniture, model: string): Promise<[THREE.Object3D, THREE.Mesh[]]> {
  return new Promise((resolve, reject) => {
    const texture = context.textureLoader.load(`/assets/3d/${model}-top.png`, () => {
        const material = new THREE.MeshBasicMaterial({map: texture, transparent: true, alphaTest: 0.5});
        const geometry = new THREE.PlaneGeometry(furniture.width.toWorld(state), furniture.height.toWorld(state));
        const image = new THREE.Mesh(geometry, material);
        image.userData.isFurniture = true;
        image.userData.construction = furniture;
        image.userData.isFurniturePart = true;

        // Resolve the promise with the image wrapped in a group
        const group = new THREE.Group();
        group.add(image);
        resolve([group, [image]]);
      },
      undefined,
      function (error) {
        console.error('An error occurred while loading the image', error);
        reject(error);
      }
    );
  });
}
